import React, { useState, useEffect } from "react";
import { Stack } from "react-bootstrap";
import { Box, Modal } from "@mui/material";
import "./styles.css";
import { useSelector } from "react-redux";
import { postMethod } from "../../helpers";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Swal from "sweetalert2"

const loader = require("../../assets/images/loading.gif").default;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth < 600 ? "90%" : "40%",
  bgcolor: "background.paper",
  border: "none !important",
  boxShadow: 2,
  p: 0,
  borderRadius: "1em",
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth < 600 ? "90%" : "40%",
  bgcolor: "background.paper",
  border: "none !important",
  boxShadow: 2,
  p: 0,
  borderRadius: "1em",
};

const metetoken = require("../../assets/images/logo_block.png").default;
const dollarimg = require("../../assets/images/dollar-sign.png").default;

function BuyBloqsModal(props) {
  let { buyBloqs, setBuyBloqs,getBloqsBalance,getUserActivity } = props;
  const [USDvalue, setUSDvalue] = useState(0);
  const [bloqsvalue, setbloqsvalue] = useState(0);
  const [error, setError] = useState("");
  //eslint-disable-next-line
  const [confirmmodal, setConfirmmodal] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [paymentpaid, setPaymentpaid] = useState(false);
  const [loading, setLoading] = useState(false);
  //eslint-disable-next-line
  const [XDCbalance, setXDCbalance] = useState(0);
  //eslint-disable-next-line
  const [Tokenbalance, setTokenbalance] = useState(0);
  const wallet = useSelector((state) => state.WalletConnect);
  const { address, connected, web3, Token, XDC_AirDrop } = wallet;
  const stripePromise = loadStripe(process.env.REACT_APP_Stripe_PublishableKey);
  const UserToken = localStorage.getItem("UserToken");

  const handlesetvalue = (e) => {
    setUSDvalue(e);
    setbloqsvalue(e * 10);
  };

  const getacc = async () => {
    var data = await web3.eth.getBalance(address);
    var tokenblc = await Token.methods.balanceOf(address).call();
    data = web3.utils.fromWei(data, "ether");
    tokenblc = web3.utils.fromWei(tokenblc, "ether");
    setTokenbalance(tokenblc);
    setXDCbalance(data);
  };

  const getstripkey = async () => {
    if (!address) {
      setError("please connect wallet");
    } else if (!USDvalue) {
      setError("USD value is Required");
    } else {
      setLoading(true);
      let url = "Stripekey";
      let params = {
        items: Number(USDvalue * 100),
      };
      let authtoken = UserToken;
      let response = await postMethod({ url, params, authtoken });
      if (response.status == true) {
        setLoading(false);
        setClientSecret(response.clientSecret);
        setBuyBloqs(false);
        setConfirmmodal(true);
      }else{
        setBuyBloqs(false);
      }
    }
  };

  const Claimbloqs = async () => {
    if (!address) {
      setError("error", "Connect Wallet");
    }
    setLoading(true);
    var time = Date.now();
    let url = "signatureXDCAirdrop";
    let amount = web3.utils.toWei(String(bloqsvalue), "ether");
    let params = {
      contractAddress: process.env.REACT_APP_XDCAIRDROP_CONTRACT,
      userAddress: address,
      amount: amount,
      isXDC: false,
      nonce: time,
    };
    let authtoken = UserToken;
    let responses = await postMethod({ url, params, authtoken });
    if (responses.signtuple) {
      try {
        //eslint-disable-next-line
        var Claim = await XDC_AirDrop.methods
          .claimAirDrops(responses.signtuple)
          .send({ from: address });
        setPaymentStatus(false);
        let url = "updateBuybloqs";
        let params = {
          client_secret: clientSecret,
        };
        let authtoken = UserToken;
        //eslint-disable-next-line
        let responses1 = await postMethod({ url, params, authtoken });
        if (responses1.status) {
          try {
            let url = "createActivitiesbloqs";
            let params = {
              wallet: address,
              hash: Claim?.transactionHash,
              from: address, //data.nftcollections_wallet,
              to: "", //address,
              type: "buybloqs",
              price: bloqsvalue,
              quantity: USDvalue,
              tranfer: "",
              amounttype: "Bloqs",
            };
            let authtoken = UserToken;
            let response = await postMethod({
              url,
              params,
              authtoken,
            });
          } catch (e) {
            console.log(e);
            setConfirmmodal(false);
          }
          setPaymentStatus(false);
          setLoading(false);
          setConfirmmodal(false);
          Swal.fire({icon: "success",text:`Your ${bloqsvalue} bloqs Claimed Successfully`});
          getBloqsBalance();
          getUserActivity()
        }
      } catch (e) {
        console.log("error", e);
        setConfirmmodal(false);
      }
    } else {
      setError("error", "Try Again");
      setConfirmmodal(false);
    }
  };
  useEffect(() => {
    if (address) {
      getacc();
    }
  }, [connected]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  return (
    <>
      <Modal
        open={buyBloqs}
        onClose={() => setBuyBloqs(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack gap={3}>
            <div className="modal_header">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <h3 className="text-light fw-bold m-0">Buy Bloqs</h3>
                </div>
                <div className="text-center">
                  <span
                    onClick={() => setBuyBloqs(false)}
                    style={{ cursor: "pointer", color: "white" }}
                    role="presentation"
                  >
                    X
                  </span>
                  <br />
                </div>
              </div>
            </div>
            <Stack gap={3} className="modal_body">
              <>
                <h6 className="mb-0">I want to spent</h6>
                <div className="buybloq-input-box">
                  <input
                    placeholder="Enter Dollar Value"
                    className="buybloqs-input"
                    value={USDvalue}
                    onChange={(e) => handlesetvalue(e.target.value)}
                  />
                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      src={dollarimg}
                      style={{ width: "20px", height: "20px" }}
                      alt="img"
                    />
                    <p className="text-center m-0">USD</p>
                  </div>
                </div>

                <h6 className="mb-0">Summary</h6>
                <div className="buybloq-input-box">
                  <input
                    placeholder="250"
                    className="buybloqs-input"
                    value={bloqsvalue}
                    readOnly
                  />
                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      src={metetoken}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                      alt="img"
                    />
                    <p className="text-center m-0">Bloqs</p>
                  </div>
                </div>
                {error !== "" && (
                  <p className="text-danger text-center">{error}</p>
                )}
              </>
              <div className="d-flex justify-content-center align-items-center">
                <button
                  className="primary-button"
                  onClick={() => getstripkey()}
                >
                  Continue
                  {loading ? (
                    <img
                      src={loader}
                      height={20}
                      width={20}
                      className="ml-2"
                      alt="img"
                    />
                  ) : null}
                </button>
              </div>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={confirmmodal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <div style={{ borderRadius: "1em" }}>
            <div
              style={{
                background: "linear-gradient(90deg, #6DC6FE 0%, #0295FA 100%)",
                borderRadius: "1em 1em 0 0 ",
              }}
              className="d-flex justify-content-between align-items-center py-3 px-3"
            >
              <h3 className="font-weight-bold m-0 text-light">Payment</h3>
              <small
                role="presentation"
                onClick={() => setConfirmmodal(false)}
                style={{ cursor: "pointer", color: "white" }}
              >
                X
              </small>
            </div>
            <div className="py-2 text-center h-100 mx-sm-5 mx-3">
              {clientSecret && !paymentStatus ? (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm
                    clientSecret={clientSecret}
                    paymentpaid={paymentpaid}
                    setPaymentpaid={setPaymentpaid}
                    data={{ amount: USDvalue, bloqs: bloqsvalue }}
                    setConfirmmodal={setConfirmmodal}
                    setPaymentStatus={setPaymentStatus}
                  />
                </Elements>
              ) : (
                <>
                  <div
                    style={{ justifyContent: " center ", marginBottom: "10px" }}
                  >
                    <h4 className="text-center">
                      Your Payment Successful <h6>Claim Your Bloqs </h6>{" "}
                    </h4>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      className="primary-button px-3"
                      onClick={() => Claimbloqs()}
                    >
                      Claim
                      {loading ? (
                        <img
                          src={loader}
                          height={20}
                          width={20}
                          className="ml-2"
                          alt="img"
                        />
                      ) : null}
                    </button>
                  </div>
                  <p className="text-success"></p>
                </>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default BuyBloqsModal;
