import React, { useState } from "react";
import Header from "../../components/header/Header";
import FeedbackModal from "../../components/modals/FeedbackModal";

function BetaVersion() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  return (
    <>
      <div className="waveinn">
        <Header />
        <div className="inner_wavein">
          <div className="inner-header wavetopinner">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="metatesting">
                    <h2>
                      <span>Metabloqs</span>
                      <h3>Friendly User Testing</h3>
                    </h2>

                    <p>
                      Please note that this is an early version of Metabloqs
                      intended for feedback and testing
                      <br />
                      purposes. Let’s build this world together.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="recommendedmain">
        <div className="container">
          <div className="row">
            <div className="metatestingright">
              <h2>Recommended system requirements</h2>
              <section className="summary">
                <div className="row">
                  <div className="col-md-4">
                    <div className="summary-item">
                      <h5 className="item-title">Processor</h5>
                      <p className="item-text">
                        <span className="item-data"></span>Core i5 9th Gen / AMD
                        FX 8100
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="summary-item">
                      <h5 className="item-title">Video</h5>
                      <p className="item-text">
                        <span className="item-data">
                          NVIDIA GeForce GT 440 / AMD Radeon HD 5670 / Intel HD
                          Graphics 5000
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="summary-item">
                      <h5 className="item-title">RAM</h5>
                      <p className="item-text">
                        <span className="item-data" id="dateData">
                          16 GB
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <p>
                The Metabloqs team is working hard to make versions for lower
                specs available soon. We will email you when the version for i3
                processors and lower is released for download and testing.
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="technology futmid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="realthing nftreal futmidinn">
                <div className="row">
                  <div className="col-md-5">
                    <div className="realthingleftnft">
                      <ul className="nftlist">
                        <li className="gapone">
                          <div className="toplistnft">
                            <img
                              src="https://static.metabloqs.com/web/assets1/images/live-in-art.png"
                              alt="img"
                            />
                          </div>
                        </li>
                        <li className="gaptwo">
                          <div className="toplistnft">
                            <img
                              src="https://static.metabloqs.com/web/assets1/images/rain-in-japan.png"
                              alt="img"
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-6">
                    <div className="realthingright">
                      <h2>How to login</h2>
                      <span>
                        Currently this build is only open for the first 5000
                        Metabloqs residents who signed up first during our
                        registration campaign.
                      </span>
                      <span>
                        Please use the same email and password for login to the
                        build. Incase you have forgotten your username and
                        password, please note that the username is the email
                        address that you received our Metabloqs email from. You
                        can recover your password by using the forgot password
                        button.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="wavemain">
        <div className="inner-header flex">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="metalivingright waveleft">
                  <img
                    src="https://static.metabloqs.com/web/assets1/images/possiblities-bg-right.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="waveright">
                  <img
                    src="https://static.metabloqs.com/web/assets1/images/beta.png"
                    alt=""
                  />
                  <h1>Friendly User Test</h1>
                  <a href="example.com">Download</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className="parallax">
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="0"
                fill="rgba(255,255,255,0.7"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="3"
                fill="rgba(255,255,255,0.5)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="5"
                fill="rgba(255,255,255,0.3)"
              />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </div>

      <div className="bluebox mt-0">
        <div className="container">
          <div className="blueboxinn futbluebox">
            <div className="row">
              <div className="col-md-12">
                <div className="blu futblueboxinn">
                  <h3>Your feedback is valuable to us.</h3>
                  {/* eslint-disable-next-line */}
                  <a onClick={() => setShow(true)} className="feedbackfut">
                    Submit feedback
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeedbackModal show={show} handleClose={handleClose} setShow={setShow} />
    </>
  );
}

export default BetaVersion;
