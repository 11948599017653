import React from "react";
import "./Explorer.css";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import Bounce from "react-reveal/Bounce";
import {Image} from "react-bootstrap"

function Explorer() {
  const navigate = useNavigate();
  const UserToken = localStorage.getItem("UserToken");
  const hang_logo =
    require("../../assets/images/possiblities-bg-right.png").default;
  const map_img = require("../../assets/images/map-bg.png").default;
  const mapp_img = require("../../assets/images/mapp.png").default;
  return (
    ""
  );
}

export default Explorer;
