import React from "react";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import Bounce from "react-reveal/Bounce";

function Launch() {
  const UserToken = localStorage.getItem("UserToken");
  const navigate = useNavigate();
  return (
    ""
  );
}

export default Launch;
