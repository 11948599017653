import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { postMethod } from "../../helpers";

function VerifyEmail() {
  const [otp, setotp] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();

  const verifyClick = async (e) => {
    e.preventDefault();
    if (!otp) {
      setError("OTP is Required!");
    } else {
      let url = "userVerification";
      let params = {
        email: state.mail,
        otp: otp,
      };
      let authtoken = "";
      let response = await postMethod({ url, params, authtoken });
      console.log("verify email res", response);
      if (response.status == true) {
        setSuccess("Account Verified Successful!");
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      } else {
        setError(response.message);
      }
    }
  };
  return (
    <>
      <div className="logingraytop" style={{ height: "100vh" }}>
        <div className="inner-login loginpage h-100">
          <div className="logingray">
            <div className="container">
              <div className="row">
                <div className="col-md-6 mx-auto">
                  <div className="logingrayinn mainloginin">
                    <div className="">
                      <h2 className="font-weight-bold text-center text-light mb-2">
                        Verify Email
                      </h2>
                      <div className="text-center text-light mb-3">
                        Please enter the OTP sent to your
                        registered email to verify your account.
                      </div>
                    </div>
                    <form className="loginformmain">
                      <div className="col-md-12 p-0">
                        <div className="form-group w-100">
                          <input
                            type="text"
                            value={otp}
                            onChange={(e) => setotp(e.target.value)}
                            className="form-control"
                            placeholder="Enter OTP"
                          />
                        </div>
                        {error !== "" ? (
                          <div className="text-center">
                            <span className="text-danger">{error}</span>
                          </div>
                        ) : null}
                        {success !== "" ? (
                          <div className="text-center">
                            <span className="text-success">{success}</span>
                          </div>
                        ) : null}
                      </div>
                      <button
                        className="primary-square-button w-100 mt-2"
                        onClick={verifyClick}
                      >
                        Verify
                        <span id="waitLoginID" className="loader-4">
                          {" "}
                        </span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyEmail;
