import React, { useEffect } from "react";
import "./App.css";
import ScrollToTop from "./helpers/ScrollToTop";
import BackToTop from "./helpers/BackToTop";
import { AuthRoutes, CommonRoutes } from "./helpers/Approutes";
import Header from "./components/header/Header";
import { useState } from "react";
import SessionTimeout from "./helpers/session/SessionTimeout";
import { useDispatch, useSelector } from "react-redux";
import { connectWallet } from "./redux/WalletAction";
import Footer from "./components/footer/Footer";
import { useLocation } from "react-router-dom";

const App = () => {
  let UserToken = localStorage.getItem("UserToken");
  const wallet = useSelector((state) => state.WalletConnect);
  const location = useLocation()
  const dispatch = useDispatch()
  //eslint-disable-next-line
  const [isAuthenticated, setAuth] = useState(true);
  const handleClick = () => {
    if (UserToken != null) {
      localStorage.clear();
      window.location.replace("/");
    }
  };

  useEffect(() => {
    const { web3Modal } = wallet;
    if (web3Modal.cachedProvider) {
      dispatch(connectWallet("metamask"));
    }
  },[]);


  return (
    <div className="App">
      <Header />
      <ScrollToTop />
      {UserToken == null || undefined ? <CommonRoutes /> : <AuthRoutes />}
      <BackToTop />
      {location.pathname == "/sidebar" || location.pathname == "/sidebar/home" || location.pathname == "/sidebar/map" || location.pathname == "/sidebar/profile" || location.pathname == "/sidebar/game" || location.pathname == "/sidebar/profile/editprofile" ? null : <Footer/> }
      <SessionTimeout isAuthenticated={isAuthenticated} logOut={handleClick} />
    </div>
  );
};

export default App;
