import React, { useState } from "react";
import ScheduleDemo from "../../components/modals/ScheduleDemo";
import "./style.css";
import { Stack, Col, Row } from "react-bootstrap";
import { Fade } from "react-reveal";

function Partner() {
  const [activeTab, setActiveTab] = useState("brand");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [isHovering1, setIsHovering1] = useState(false);
  const [isHovering2, setIsHovering2] = useState(false);
  const [isHovering3, setIsHovering3] = useState(false);
  const [isHovering4, setIsHovering4] = useState(false);
  const [msg,setmsg] = useState("")

  const tabToggle = (item) => {
    if (item == "brand") {
      setActiveTab("brand");
    } else if (item == "event") {
      setActiveTab("event");
    } else if (item == "education") {
      setActiveTab("education");
    }
  };

  const handleMouseEnter1 = () => {
    setIsHovering1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovering1(false);
  };
  const handleMouseEnter2 = () => {
    setIsHovering2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovering2(false);
  };

  const handleMouseEnter3 = () => {
    setIsHovering3(true);
  };

  const handleMouseLeave3 = () => {
    setIsHovering3(false);
  };

  const handleMouseEnter4 = () => {
    setIsHovering4(true);
  };

  const handleMouseLeave4 = () => {
    setIsHovering4(false);
  };

  return (
    <>
      <section className="banefits" id="partner">
        <Stack gap={3}>
          <h1
            className="text-center font-weight-bold"
            style={{ color: "#0295fa" }}
          >
            Become a Metabloqs partner
          </h1>
          <p className="text-center" style={{ color: "#67808E" }}>
            For everyone from businesses and brands, to academic institutions
            and city authorities, <br /> Metabloqs is the gateway to the
            metaverse.
          </p>
          <Fade bottom>
            <Row
              className=""
              style={{ margin: window.innerWidth < 600 ? "0px 0px" : "0px 10%" }}
            >
              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="mb-3">
                <div className="cols-radius">
                  <div
                    className="partner-img-card1"
                    onMouseEnter={handleMouseEnter1}
                    onMouseLeave={handleMouseLeave1}
                  >
                    {isHovering1 ? (
                      <div className="center h-100">
                        <button
                          onClick={() => {setShow(true);setmsg("Meta Paris")}}
                          className="primary-square-button mt-3"
                        >
                          Schedule a Demo
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <h4 className="text-center partner-img-heading py-2 mb-0 font-weight-bold">
                    Meta Paris
                  </h4>
                </div>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="mb-3">
                <div className="cols-radius">
                  <div className="partner-img-card2">
                    <div
                      className="partner-img-card2"
                      onMouseEnter={handleMouseEnter2}
                      onMouseLeave={handleMouseLeave2}
                    >
                      {isHovering2 ? (
                        <div className="center h-100">
                          <button
                            onClick={() => {setShow(true);setmsg("Meta Games")}}
                            className="primary-square-button mt-3"
                          >
                            Schedule a Demo
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <h4 className="text-center partner-img-heading py-2 mb-0 font-weight-bold">
                    Meta Games
                  </h4>
                </div>
              </Col>
            </Row>
          </Fade>
          <Fade bottom>
            <Row
              className=""
              style={{ margin: window.innerWidth < 600 ? "0px 0px" : "0px 10%" }}
            >
              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="mb-3">
                <div className="cols-radius">
                  <div className="partner-img-card3">
                    <div
                      className="partner-img-card3"
                      onMouseEnter={handleMouseEnter3}
                      onMouseLeave={handleMouseLeave3}
                    >
                      {isHovering3 ? (
                        <div className="center h-100">
                          <button
                            onClick={() => {setShow(true);setmsg("Meta Lands")}}
                            className="primary-square-button mt-3"
                          >
                            Schedule a Demo
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <h4 className="text-center partner-img-heading py-2 mb-0 font-weight-bold">
                    Meta Lands
                  </h4>
                </div>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="mb-3">
                <div className="cols-radius">
                  <div className="partner-img-card4">
                    <div
                      className="partner-img-card4"
                      onMouseEnter={handleMouseEnter4}
                      onMouseLeave={handleMouseLeave4}
                    >
                      {isHovering4 ? (
                        <div className="center h-100">
                          <button
                            onClick={() => {setShow(true);setmsg("Meta Events")}}
                            className="primary-square-button mt-3"
                          >
                            Schedule a Demo
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <h4 className="text-center partner-img-heading py-2 mb-0 font-weight-bold">
                    Meta Events
                  </h4>
                </div>
              </Col>
            </Row>
          </Fade>

          <Row className="mt-5 mx-5">
            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              onClick={() => tabToggle("brand")}
              className="mb-2"
            >
              <div className="text-center" style={{ cursor: "pointer" }}>
                <h4
                  className="font-weight-bold mb-0"
                  style={{ color: "#374A55" }}
                >
                  BRANDED EXPERIENCES
                </h4>
                <small style={{ color: "#374A55" }}>
                  More than another touch point
                </small>
                <div
                  className={
                    activeTab == "brand"
                      ? "gradient_line my-2 mx-5"
                      : "black_line my-2 mx-5"
                  }
                ></div>
                <small>
                  Build immersive brand experiences in virtual <br /> offices,
                  stores, showrooms, consultation <br /> rooms, and galleries.
                </small>
              </div>
            </Col>
            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              onClick={() => tabToggle("event")}
              className="mb-2"
            >
              <div className="text-center" style={{ cursor: "pointer" }}>
                <h4
                  className="font-weight-bold mb-0"
                  style={{ color: "#374A55" }}
                >
                  EVENTS
                </h4>
                <small style={{ color: "#374A55" }}>
                  Authentic connections & interactions
                </small>
                <div
                  className={
                    activeTab == "event"
                      ? "gradient_line my-2 mx-5"
                      : "black_line my-2 mx-5"
                  }
                ></div>
                <small>
                  Authentic connections & interactions <br /> Host virtual
                  events to foster meaningful <br /> interactions with clients,
                  investors, <br />
                  customers, employees or business partners.
                </small>
              </div>
            </Col>
            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              onClick={() => tabToggle("education")}
              className="mb-2"
            >
              <div className="text-center" style={{ cursor: "pointer" }}>
                <h4
                  className="font-weight-bold mb-0"
                  style={{ color: "#374A55" }}
                >
                  EDUCATION AND LEARNING
                </h4>
                <small style={{ color: "#374A55" }}>
                  Diving into deep knowledge
                </small>
                <div
                  className={
                    activeTab == "education"
                      ? "gradient_line my-2 mx-5"
                      : "black_line my-2 mx-5"
                  }
                ></div>
                <small>
                  Create engaging educational <br /> experiences that result in
                  better <br />
                  learning outcomes.
                </small>
              </div>
            </Col>
          </Row>
          {activeTab == "brand" ? (
            <Row className="" style={{ margin:  window.innerWidth < 600 ? "1% 3%" :"1% 8%" }}>
              <div className="partner-brand-section">
                <h3 className="text-center text-light font-weight-bold">
                  Branded Experiences: <br />
                  More than another touchpoint
                </h3>
                <span className="text-center text-light mt-2">
                  A unique opportunity to provide a compelling brand experience
                  to your customers or employees. Highly <br /> immersive
                  virtual environments enable people to learn about your
                  business, products or services, interact <br /> with you, and
                  make purchases. Build your own branded virtual office, store,
                  showroom, consultation room, <br /> gallery or experiential
                  venue… get creative!{" "}
                </span>
              </div>
              <div className="center">
                <button
                  onClick={() => setShow(true)}
                  className="primary-square-button mt-3"
                >
                  Schedule a Demo
                </button>
              </div>
            </Row>
          ) : activeTab == "event" ? (
            <Row className="" style={{ margin:  window.innerWidth < 600 ? "1% 3%" :"1% 8%" }}>
              <div className="partner-event-section">
                <h3 className="text-center text-light font-weight-bold">
                  Events: <br />
                  Authentic connections & interactions
                </h3>
                <span className="text-center text-light mt-2">
                  Strong connections are built on trusted human relationships.
                  Our immersive experience <br /> provides an ideal way to
                  generate sustainable business impact. Hold events of any sort
                  on <br /> a one-off basis, or via a permanent space or venue
                  dedicated to interacting with clients, <br /> investors,
                  customers, employees or business partners.
                </span>
              </div>
              <div className="center">
                <button
                  onClick={() => setShow(true)}
                  className="primary-square-button mt-3"
                >
                  Schedule a Demo
                </button>
              </div>
            </Row>
          ) : (
            <Row className="" style={{margin: window.innerWidth < 600 ? "1% 3%" :"1% 8%"  }}>
              <div className="partner-education-section">
                <h3 className="text-center text-light font-weight-bold">
                  Education & Learning: <br /> Diving into deep knowledge
                </h3>
                <span className="text-center text-light mt-2">
                  Immersive learning experiences are not only more engaging,
                  they also deliver superior <br /> learning outcomes. Conduct
                  any type of interactive training course, class, or lecture in
                  a <br />
                  dedicated space using our purpose-built education UI.
                </span>
              </div>
              <div className="center">
                <button
                  onClick={() => {setShow(true);setmsg("")}}
                  className="primary-square-button mt-3"
                >
                  Schedule a Demo
                </button>
              </div>
            </Row>
          )}
        </Stack>
      </section>
      <ScheduleDemo show={show} handleClose={handleClose} setShow={setShow} msg={msg} setmsg={setmsg}/>
    </>
  );
}

export default Partner;
