import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { connectFailed } from "../../redux/WalletAction";

function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const wallet = useSelector((state) => state.WalletConnect);
  const dispatch = useDispatch();

  const errorDiv = () => {
    return <p>Wallet Disconnected!</p>;
  };
  const disconnect = () => {
    const { web3Modal } = wallet;
    web3Modal.clearCachedProvider();
    dispatch(connectFailed(errorDiv()));
  };

  const logout = () => {
    Swal.fire({
      text: "Are you sure want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0295fa",
      cancelButtonColor: "#808080",
      confirmButtonText: "Logout"
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        if(wallet.connected){
          disconnect();
        }
        navigate("/");
      }
    })
  };


  return (
    <>
      <section className="sect section1 regmain mt-5">
        <div className="inner-sidebar">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-1 col-12 col-sm-12 h-100 left-mob-dashboard">
                <div className="leftdashboard ">
                  <div
                    className="nav flex-sm-column nav-pills leftprofilebtninn"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <Link
                      to="profile"
                      className={
                        location.pathname == "/sidebar/profile" || location.pathname == "/sidebar/profile/editprofile"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <div className="dashmenuprofile"></div>
                      <span>Profile</span>
                    </Link>
                    <Link
                      to="home"
                      className={
                        //eslint-disable-next-line
                        location.pathname == "/sidebar/home"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <div className="dashmenuone"></div>
                      <span> Metaverse</span>
                    </Link>

                    <Link
                      to="map"
                      className={
                        location.pathname == "/sidebar/map"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <div className="dashmenufive"></div>
                      <span>Map</span>
                    </Link>

                    <Link
                      to="game"
                      className={
                        location.pathname == "/sidebar/game"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <div className="dashmenugame"></div>
                      <span>Game</span>
                    </Link>
                    <button onClick={logout} className="nav-link bottomlogout">
                      <div className="dashmenulogout"></div>
                      <span>Logout</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-10 h-100 mx-auto">
                <div className="" style={{ overflow: "hidden" }}>
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Sidebar;
