import React from "react";

function WhitePaper() {
  return (
    <div className="mx-3 mt-5 pt-4">
      <iframe
        src="https://s3.eu-central-1.amazonaws.com/static.metabloqs.com/public/Metabloqs_Whitepaper.pdf"
        frameBorder="0"
        height="800px"
        width="100%"
        title="whitepaper"
      ></iframe>
    </div>
  );
}

export default WhitePaper;
