import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import store from "./store";
import TokenABI from "../contracts/TokenABI.json";
import XDCAirDropJson from "../contracts/XDC_AirDrop.json";

const currentchainid = process.env.REACT_APP_CHAIN_ID

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

export const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

export const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const getProviderOptions = () => {
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
          51: "https://rpc.apothem.network",
          50: "https://rpc1.xinfin.network",
          4: "https://rinkeby.infura.io/v3/",
          5: "https://goerli.infura.io/v3/",
        },
      },
    },
  };

  return providerOptions;
};

export const connectWallet = (walletname) => {
  return async (dispatch) => {
    dispatch(connectRequest());
    try {
      const web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions: getProviderOptions(), // required
      });

      var provider = "";
      if (walletname === "coinbasewallet") {
        //eslint-disable-next-line
        var provider = await web3Modal.connectTo("coinbasewallet");
      } else if (walletname === "walletconnect") {
        //eslint-disable-next-line
        var provider = await web3Modal.connectTo("walletconnect");
      } else if (walletname === "fortmatic") {
        //eslint-disable-next-line
        var provider = await web3Modal.connectTo("fortmatic");
      } else if (walletname === "metamask") {
        const web3Modal = new Web3Modal({
          cacheProvider: true,
          providerOptions: getProviderOptions().walletconnect, // required
        });
        //eslint-disable-next-line
        var provider = await web3Modal.connect();
      }
      else {
        const web3Modal = new Web3Modal({
          cacheProvider: true,
          providerOptions: getProviderOptions().walletconnect, // required
        });
        //eslint-disable-next-line
      provider = await web3Modal.connect();
      }

      await subscribeProvider(provider);

      const web3 = new Web3(provider);

      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];    

      const XDC_AirDrop = new web3.eth.Contract(
        XDCAirDropJson,
        process.env.REACT_APP_XDCAIRDROP_CONTRACT
      );
      const Token = new web3.eth.Contract(
        TokenABI,
        process.env.REACT_APP_TOKEN_CONTRACT
      )
      if (
        window.ethereum &&
        window.ethereum.networkVersion !== currentchainid
      ) {
        await addNetwork(currentchainid);
      }

      dispatch(
        connectSuccess({
          address,
          web3,
          provider,
          connected: true,
          web3Modal,
          Token,
          XDC_AirDrop: XDC_AirDrop,
        })
      );
    } catch (e) {
      dispatch(connectFailed(e));
    }
  };
};

const subscribeProvider = async (provider) => {
  if (!provider.on) {
    return;
  }

  provider.on("connect", async (id) => {
    console.log(id);
  });

  // provider.on("networkChanged", async (networkId) => {
  //   if (networkId !== 5) {
  //     console.log(networkId);
  //     store.dispatch(connectFailed("Please switch to mainnet"));
  //   } else {
  //     store.dispatch(connectWallet("metamask"));
  //   }
  // });
};

export async function addNetwork(id) {
  let networkData;
  switch (parseInt(id)) {
  //bsctestnet
  case 97:
    networkData = [
      {
        chainId: "0x61",
        chainName: "BSCTESTNET",
        rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
        nativeCurrency: {
          name: "BINANCE COIN",
          symbol: "BNB",
          decimals: 18,
        },
        blockExplorerUrls: ["https://testnet.bscscan.com/"],
      },
    ];

    break;
  //bscmainet
  case 56:
    networkData = [
      {
        chainId: "0x38",
        chainName: "BSCMAINET",
        rpcUrls: ["https://bsc-dataseed1.binance.org"],
        nativeCurrency: {
          name: "BINANCE COIN",
          symbol: "BNB",
          decimals: 18,
        },
        blockExplorerUrls: ["https://testnet.bscscan.com/"],
      },
    ];
    break;
  case 80001:
    networkData = [
      {
        chainId: "0x13881",
        chainName: "Polygon testnet",
        rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18,
        },
        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
      },
    ];
    break;
  case 50:
    networkData = [
      {
        chainId: "0x32",
        chainName: "XinFin XDC Network",
        rpcUrls: ["https://erpc.xinfin.network/"],
        nativeCurrency: {
          name: "XinFin XDC Network",
          symbol: "XDC",
          decimals: 18,
        },
        blockExplorerUrls: ["https://xdcscan.io"],
      },
    ];
    break;
  case 5:
    networkData = [
      {
        chainId: "0x5",
      },
    ];
    break;
  default:
    break;
  }
  if (Number(id) != currentchainid) {
    return window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: networkData,
    });
  } else {
    return window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: networkData,
    });
  }
}

(() => {
  if (window.ethereum) {
    window.ethereum.on("networkChanged", function (networkId) {
      if (networkId !== currentchainid) {
        console.log(networkId);
        store.dispatch(connectFailed("Please switch to Polygon mainnet"));
      } else {
        store.dispatch(connectWallet("metamask"));
      }
    });
  }
})();
