import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { postMethod } from "../../helpers";

function ResetPassword() {
  const [otp, setOtp] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const navigate = useNavigate();
  const { state } = useLocation();

  const submitClick = async (e) => {
    e.preventDefault();
    if (!otp) {
      setError("OTP is Required!");
    } else if (!password1) {
      setError("New Password is Required!");
    } else if (!password2) {
      setError("Confirm Password is Required!");
    } else if (password1 !== password2) {
      setError("Both Password should be Same");
    } else {
      let password = password1.trim();
      let url = "resetPassword";
      let params = {
        otp: otp,
        password: password,
        email: state.mail,
      };
      let authtoken = "";
      let response = await postMethod({ url, params, authtoken });
      console.log("reset pswd msg", response);
      if (response.status == true) {
        if(state.existing !== null){
          setSuccess("Password Reset Successful!");
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }else{
          setSuccess("Password Reset Successful!");
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
      } else {
        setError(response.message);
      }
    }
  };
  return (
    <>
      <div className="logingraytop" style={{ height: "100vh" }}>
        <div className="inner-login loginpage h-100">
          <div className="logingray">
            <div className="container">
              <div className="row">
                <div className="col-md-6 mx-auto">
                  <div className="logingrayinn mainloginin">
                    <div className="logingrayinnblur">
                      <h2 className="font-weight-bold text-center">Reset password </h2>
                    </div>
                    <form className="loginformmain">
                      <div className="col-md-12 p-0">
                        <div className="form-group w-100">
                          <input
                            type="text"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            className="form-control"
                            placeholder="OTP"
                          />
                        </div>
                        <div className="form-group w-100">
                          <input
                            type="password"
                            value={password1}
                            onChange={(e) => setPassword1(e.target.value)}
                            className="form-control"
                            placeholder="New Password"
                          />
                        </div>
                        <div className="form-group w-100">
                          <input
                            type="password"
                            value={password2}
                            onChange={(e) => setPassword2(e.target.value)}
                            className="form-control"
                            placeholder="Confirm Password"
                          />
                        </div>
                        {error !== "" ? (
                          <div className="text-center">
                            <span className="text-danger">{error}</span>
                          </div>
                        ) : null}
                        {success !== "" ? (
                          <div className="text-center">
                            <span className="text-success">
                              {success}
                            </span>
                          </div>
                        ) : null}
                      </div>
                      <button
                        className="primary-square-button w-100 mt-2"
                        onClick={submitClick}
                      >
                        Submit
                        <span id="waitLoginID" className="loader-4">
                          {" "}
                        </span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
