import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./styles.css";

function ScheduleDemo({ show, handleClose, setShow,msg,setmsg }) {
  const email = "dev.metablocks@gmail.com";
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [details, setDetails] = useState(msg);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="my-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-body schedulebody">
        <div className="logingrayinn mainloginin">
          <button
            type="button"
            className="btn-close"
            onClick={() => {setShow(false);setmsg("")}}
          ></button>
          <h2 className="mb-1">Schedule a Live Demo</h2>
          <div className="text-center text-light my-1">{msg}</div>
          <i></i>
          <form className="scheduleinn">
            <div className="form-group">
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Full Name"
                required
              />
            </div>
            <div className="form-group">
              <input
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Email Address"
                required
              />
            </div>
            <div className="form-group">
              <textarea
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                className="form-control"
                placeholder="Details"
                required
                rows="3"
              ></textarea>
            </div>
            {!name && !mail ? (null) : (
              <div className="primary-button center">
                <a
                  href={`mailto:${email}?subject=${name}&body=${details}`}
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Submit
                </a>
              </div>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default ScheduleDemo;
