import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { postMethod } from "../../helpers";
// import {AiOutlineEyeInvisible} from "react-icons/ai"

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //eslint-disable-next-line
  const [captcha, setCaptcha] = useState("");
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();
  //eslint-disable-next-line
  const [showPassword,setshowPassword] = useState(false);

  const loginIn = async (e) => {
    e.preventDefault();
    if (!email) {
      setErrors("Email is Required!");
    } else if (!password) {
      setErrors("Password is Required!");
    } else {
      let mail = email.trim();
      let pass = password.trim();
      let url = "signin";
      let params = {
        email: mail,
        password: pass,
      };
      let authtoken = "";
      let response = await postMethod({ url, params, authtoken });
      console.log("login response=", response);
      if (response.status == true) {
        setEmail("");
        setPassword("");
        setCaptcha("");
        localStorage.setItem("UserToken", response.userToken);
        localStorage.setItem("UserEmail",response.user.user_email);
        localStorage.setItem("UserWallet",response.user.user_wallet);
        localStorage.setItem("UserName",response.user.user_name);
        let user = {
          referral_code:response.user.referral_code,
          rewards:response.rewards,
          profile_img:response.user.user_image,
          facebooklink:response.user.facebooklink,
          twitterlink:response.user.twitterlink,
          telegramlink:response.user.telegramlink,
          instagramlink:response.user.instagramlink,
          whatsapplink:response.user.whatsapplink,
          first_name:response.user.first_name,
          last_name:response.user.last_name,
          user_id:response.user.user_id,
          user_name:response.user.user_name,
          banner_img:response.user.user_bannerimage
        }
        localStorage.setItem("User",JSON.stringify(user));
        navigate("/sidebar/profile");
        window.location.reload();
      } else {
        setErrors(response.message);
        if (response.message == "Update your password") {
          setTimeout(() => {
            navigate("/forgotpswd", { state: { existing: "existing" } });
          }, 2000);
        } else if (response.message == "User not found") {
          setTimeout(() => {
            navigate("/signup");
          }, 2000);
        }
      }
    }
  };

  function onChange(value) {
    setCaptcha(value);
  }
  return (
    <>
      <div className="logingraytop" style={{ height: "100vh" }}>
        <div className="inner-login loginpage h-100">
          <div className="logingray">
            <div className="container">
              <div className="row">
                <div className="col-md-6 mx-auto">
                  <div className="logingrayinn mainloginin">
                    <div className="logingrayinnblur">
                      <h2 className="font-weight-bold text-center">Login</h2>
                    </div>
                    <form className="loginformmain">
                      <div className="col-md-12 p-0">
                        <div className="form-group">
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control reginput"
                            placeholder="Email address"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 p-0">
                        <div className="form-group">
                          <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control"
                            placeholder="Password"
                          />
                          {/* <AiOutlineEyeInvisible size={18} color="black" onClick={()=> showPassword ? setshowPassword(false) : setshowPassword(true)}/> */}
                        </div>
                      </div>
                      <div className="col-md-12 mb-3 p-0">
                        <div className="form-group">
                          <ReCAPTCHA
                            sitekey="6LfztHoiAAAAAHvP1d4AAuT2UpJs5cOePyQ5O53i"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      {errors !== "" ? (
                        <div className="text-center mb-2">
                          <span className="text-danger">{errors}</span>
                        </div>
                      ) : null}
                      <p className="m-0 p-0">
                        {/* eslint-disable-next-line */}
                        <a
                          onClick={() => navigate("/forgotpswd",{ state: { existing: null }})}
                          className="forgot"
                          style={{ marginBottom: "5px", cursor: "pointer" }}
                        >
                          Forgot Password?
                        </a>
                      </p>
                      <button
                        type="submit"
                        className="primary-square-button w-100"
                        onClick={loginIn}
                      >
                        Login
                      </button>
                      <p className="m-0 p-0 text-right">
                        {/* eslint-disable-next-line */}
                        <a
                          onClick={() => navigate("/signup")}
                          className="forgot"
                          style={{
                            marginTop: "5px",
                            textAlign: "right",
                            cursor: "pointer",
                          }}
                        >
                          Register
                        </a>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
