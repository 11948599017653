import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { postMethod, Slicer } from "../../helpers";
import { connectFailed } from "../../redux/WalletAction";
import "./styles.css";

function UpdateWalletModal({ updateWallet, setUpdateWallet }) {
  const wallet = useSelector((state) => state.WalletConnect);
  const UserEmail = localStorage.getItem("UserEmail")
  const UserToken = localStorage.getItem("UserToken");
  const dispatch = useDispatch();
  const { address, web3Modal } = wallet;
  

  const errorDiv = () => {
    return <p>Wallet Disconnected!</p>;
  };
  const disconnect = () => {
    setUpdateWallet(false);
    web3Modal.clearCachedProvider();
    dispatch(connectFailed(errorDiv()));
  };

  const updateUserWallet = async () => {
    try {
      let url = "UpdateuserWallet";
      let params = {
        email: UserEmail,
        address: address,
      };
      let authtoken = UserToken;
      let response = await postMethod({ url, params, authtoken });
      if(response.status == true){
        setUpdateWallet(false);
        localStorage.setItem("UserWallet",address)
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      show={updateWallet}
      onHide={() => {setUpdateWallet(false);disconnect()}}
      className="my-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-body schedulebody">
        <div className="logingrayinn mainloginin">
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => {setUpdateWallet(false);disconnect()}}
          ></button>
          <h4 className="text-center text-light">Message !</h4>
          <p className="text-center text-light">
            {Slicer(address)} is the wallet that
            you prefer to use in Metabloqs, if not change the wallet
          </p>
          <i></i>
          <Row>
            <Col>
              <button
                type="submit"
                className="empty-button w-100"
                onClick={disconnect}
              >
                CHANGE
              </button>
            </Col>
            <Col>
              <button
                type="submit"
                className="primary-button w-100"
                onClick={updateUserWallet}
              >
                YES
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
}

export default UpdateWalletModal;
