import React, { useEffect, useState } from "react";
import { Box, CardMedia, Typography, Modal, Radio } from "@mui/material";
// import Images from "../../assets/Images";
import useStyles from "./styles";
import {
  useDispatch,
  useSelector,
  // useSelector
} from "react-redux";
import { connectFailed, connectWallet } from "../../redux/WalletAction";
import { postMethod, Slicer } from "../../helpers";
import { useNavigate } from "react-router-dom";
import UpdateWalletModal from "../modals/UpdateWalletModal";
import MessageModal from "../modals/MessageModal";

function ConenctWallet({ openWallet, setOpenWallet }) {
  const wallet = useSelector((state) => state.WalletConnect);
  const { address, web3Modal } = wallet;
  const UserEmail = localStorage.getItem("UserEmail");
  const UserToken = localStorage.getItem("UserToken");


  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = React.useState("Metamask");
  const [selectedwallet, setSelectedwallet] = React.useState("metamask");
  // const [execute, setExecute] = useState(false);
  const [updateWallet, setUpdateWallet] = useState(false);
  const [msgShow, setMsgShow] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleChange = (event) => {
    setSelectedValue(event.title);
    setSelectedwallet(event.wallet);
  };

  const img1 = require("../../assets/images/metamask.png").default;
  const img2 = require("../../assets/images/coinbase.png").default;
  const img3 = require("../../assets/images/wallet.png").default;
  const img4 = require("../../assets/images/fortmatic.png").default;

  const walletData = [
    {
      title: "Metamask",
      icon: img1,
      wallet: "metamask",
    },
    {
      title: "Coinbase Wallet",
      icon: img2,
      wallet: "coinbasewallet",
    },
    {
      title: "WalletConnect",
      icon: img3,
      wallet: "walletconnect",
    },
    {
      title: "Fortmatic",
      icon: img4,
      wallet: "fortmatic",
    },
  ];

  const errorDiv = () => {
    return <p>Wallet Disconnected!</p>;
  };
  const disconnect = () => {
    // const { web3Modal } = wallet;
    web3Modal.clearCachedProvider();
    dispatch(connectFailed(errorDiv()));
  };

  const walletSign = async () => {
    let url = "walletsignin";
    let params = {
      wallet: address,
    };
    let authtoken = "";
    let response = await postMethod({ url, params, authtoken });
    if (response.status == true) {
      // setExecute(true);
      if (UserToken) {
        if (UserEmail == response.user.user_email) {
          localStorage.setItem("UserWallet",response.user.user_wallet)
        } else {
          disconnect();
          if (UserToken) {
            setMsgShow(true); //msg wallet open 
            setMessage(
              `${UserEmail} don't have ${Slicer(
                response.user.user_wallet
              )} this wallet address, you are not allowed to change the wallet`
            );
          }
        }
      }
      // localStorage.setItem("UserToken", response.userToken);
      // navigate("/sidebar");
    } else {
      if (response.message == "User not found" && !UserToken) {
        disconnect();
        setMsgShow(true); //msg wallet open 
        setMessage("Please Register");
        setTimeout(()=>{
          setMsgShow(false)
          navigate("/signup");
        },2000)
      } else if(response.message == "User not found" && localStorage.getItem("UserWallet") !== "null") {
        setMsgShow(true); //msg wallet open 
        setMessage(
          `${UserEmail} is already registered with ${Slicer(
            localStorage.getItem("UserWallet")
          )} this wallet address, you are not allowed to change the wallet`
        );
        disconnect();
      } else{
        setUpdateWallet(true);
      }
    }
  };
  const connect = async (walletname) => {
    setOpenWallet(false);
    dispatch(connectWallet(walletname));
  };

  useEffect(() => {
    if (wallet.connected) {
      walletSign();
    }
  }, [wallet]);


  return (
    <>
      <Box>
        <Modal open={openWallet} onClose={() => setOpenWallet(false)}>
          <Box className={classes.walletContainer}>
            <Box className={classes.modalHeader}>
              <Typography variant="h5" className={classes.walletTitle}>
                Connect your wallet
              </Typography>
              {/* eslint-disable-next-line */}
              <h6 className="m-0" onClick={() => setOpenWallet(false)}>
                X
              </h6>
            </Box>

            <Box className={classes.divider} />
            <Box className={classes.itemContainer}>
              {walletData.map((item, index) => (
                <Box className={classes.radioContainer} key={index}>
                  <Box className={classes.radioTitle}>
                    <CardMedia
                      component="img"
                      image={item.icon}
                      className={classes.icon}
                    />
                    <Typography variant="body1" className={classes.title}>
                      {item.title}
                    </Typography>
                  </Box>
                  <Radio
                    checked={selectedValue === item.title}
                    onChange={() => handleChange(item)}
                    value={item.title}
                    name="radio-buttons"
                    inputProps={{ "aria-label": item.title }}
                  />
                </Box>
              ))}
            </Box>
            <Box className={classes.btnContainer}>
              <button
                className="primary-button"
                onClick={() => connect(selectedwallet)}
              >
                Connect Wallet
              </button>
            </Box>
          </Box>
        </Modal>
      </Box>
      <UpdateWalletModal
        updateWallet={updateWallet}
        setUpdateWallet={setUpdateWallet}
      />
      <MessageModal
        msgShow={msgShow}
        setMsgShow={setMsgShow}
        message={message}
      />
    </>
  );
}

export default ConenctWallet;
