import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { postMethod } from "../../helpers";

function CheckoutForm(props) {
  let {
    clientSecret,
    data,
    setPaymentStatus,
    setPaymentpaid,
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading,setLoading] = useState(false)
  const wallet = useSelector((state) => state.WalletConnect);
  const { address } = wallet;
  const Loderimg = require("../../assets/images/loading.gif").default;

  const uploadpayment = async (result) => {
    let url = "Buybloqs";
    let params = {
      wallet: address,
      amount: Number(data.amount) * 100,
      userid: 0,
      paymet_status: result.status,
      client_secret: clientSecret,
      payment_id: result.id,
      payment_currency: "USD",
      bloqs: data.bloqs,
    };
    let authtoken = "";
    let response = await postMethod({ url, params, authtoken });
    setPaymentStatus(true);
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!address) {
      alert("Connect Wallet");
    }
    if (!stripe || !elements) {
      return;
    }
    setPaymentpaid(true);
    setLoading(true);
    setIsLoading(true);
    stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      })
      .then(function (result) {
        if (result.paymentIntent) {
          uploadpayment(result.paymentIntent);
          setMessage(result.paymentIntent.status);
        }
      });
    setIsLoading(false);
    setPaymentpaid(false);
  };
  useEffect(() => {
    if (!stripe) {
      return;
    }
    if (!elements) {
      return;
    }

    if (!clientSecret) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
      case "succeeded":
        setMessage("Payment succeeded!");
        break;
      case "processing":
        setMessage("Your payment is processing.");
        break;
      case "requires_payment_method":
        setMessage("Your payment was not successful, please try again.");
        break;
      default:
        setMessage("Something went wrong.");
        break;
      }
    });
  }, [stripe]);
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <div className="d-flex justify-content-center align-items-center">
        <button
          className="primary-button"
          style={{ marginTop: "10px" }}
          disabled={isLoading || !stripe || !elements}
          id="submit"
        >
          <small>Pay Now {
            loading == true ? <img src={Loderimg} height={20} width={20} className="ml-1" style={{zIndex:99}} alt="img"/> : null
          }</small>
          
        </button>
      </div>
      {/* Show any error or success messages */}
      {message !== "" && <div id="payment-message mt-2">{message}</div>}
    </form>
  );
}

export default CheckoutForm;
