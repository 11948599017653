import React from "react";

function Game() {
  return (
    <div className="">
      <div className="homedadshbg gamedashbg">
        <div className="contentofvideo">
          <video
            loop={true}
            muted={false}
            autoPlay={true}
            id="myVideo1"
            style={{ borderRadius: "1em" }}
            controls={true}
            playsInline={true}
          >
            <source
              src="https://metabloqs-nft.s3-eu-central-1.amazonaws.com/metabloqs/applicationvideos/1669093054646Logo.mp4"
              type="video/mp4"
            />
            <track
              default
              kind="captions"
              srcLang="en"
              src="/media/examples/friday.vtt"
            />
          </video>
        </div>
      </div>
    </div>
  );
}

export default Game;
