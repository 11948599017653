import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { postMethod } from "../../helpers";

function Signup() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [referralCode,setReferralCode] = useState("");
  //eslint-disable-next-line
  const [captcha, setCaptcha] = useState("");
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const createAcc = async (e) => {
    e.preventDefault();
    if (!username) {
      setErrors("Username is Required!");
    } else if (!email) {
      setErrors("Email is Required!");
    } else if (!password) {
      setErrors("Password is Required!");
    } 
    // else if (!captcha) {
    //   setErrors("Captcha is Required!");
    // } 
    else {
      let name = username.trim();
      let mail = email.trim();
      let pass = password.trim();
      let refer = referralCode.trim()
      let url = "Signup";
      let params = {
        email: mail,
        username: name,
        password: pass,
        reffered_by:refer
      };
      let authtoken = "";
      let response = await postMethod({ url, params, authtoken });
      console.log("signup response=", response);
      if (response.status == true) {
        setEmail("");
        setPassword("");
        setUsername("");
        setCaptcha("");
        navigate("/verifyemail", { state: { mail: mail } });
      } else {
        setErrors(response.result);
      }
    }
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    setCaptcha(value);
  }

  useEffect(() => {
    var query = window.location.search.substring(1);
    const params = new URLSearchParams(query);
    const referal_id = params.get("id");
    if (referal_id) {
      setReferralCode(referal_id)
    }
  }, [id])

  return (
    <>
      <div className="logingraytop" style={{ height: "100vh" }}>
        <div className="inner-login loginpage h-100">
          <div className="logingray">
            <div className="container">
              <div className="row">
                <div className="col-md-6 mx-auto">
                  <div className="logingrayinn mainloginin">
                    <div className="logingrayinnblur">
                      <h2 className="font-weight-bold text-center">Create Account</h2>
                    </div>
                    <form className="loginformmain">
                      <div className="col-md-12 p-0">
                        <div className="form-group">
                          <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="form-control reginput"
                            placeholder="Username"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 p-0">
                        <div className="form-group">
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control reginput"
                            placeholder="Email address"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 p-0">
                        <div className="form-group">
                          <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control"
                            placeholder="Password"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 p-0">
                        <div className="form-group">
                          <input
                            type="text"
                            value={referralCode}
                            onChange={(e) => setReferralCode(e.target.value)}
                            className="form-control reginput"
                            placeholder="Referral code"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3 p-0">
                        <div className="form-group">
                          <ReCAPTCHA
                            sitekey="6LfztHoiAAAAAHvP1d4AAuT2UpJs5cOePyQ5O53i"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      {errors !== "" ? (
                        <div className="text-center mb-2">
                          <span className="text-danger">{errors}</span>
                        </div>
                      ) : null}
                      <button type="submit" className="primary-square-button w-100" onClick={createAcc}>
                        Create Account
                      </button>
                      <p className="m-0 p-0 text-left">
                        Already have an account?
                        {/* eslint-disable-next-line */}
                        <a
                          onClick={() => navigate("/login")}
                          className="forgot"
                          style={{
                            marginTop: "5px",
                            marginLeft: "5px",
                            textAlign: "left",
                            cursor: "pointer",
                          }}
                        >
                          Login
                        </a>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
