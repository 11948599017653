import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

const getProviderOptions = () => {
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        //infuraId: process.env.REACT_APP_INFURA_ID
        rpc: {
          51: "https://rpc.apothem.network",
          50: "https://rpc1.xinfin.network",
          4: "https://rinkeby.infura.io/v3/",
          5: "https://goerli.infura.io/v3/",
        },
      },
    },
  };

  return providerOptions;
};

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions: getProviderOptions(), // required
});



const initialState = {
  loading: false,
  address: "",
  connected: false,
  web3: null,
  provider: null,
  token: null,
  errorMsg: null,
  reward: null,
  web3Modal:web3Modal,
  Token:null,
  XDC_AirDrop: null,

}

const walletConnectReducer = (state = initialState, action) => {
  switch (action.type) {
  case "CONNECTION_REQUEST":
    return {
      ...initialState,
      loading: true,
    };
  case "CONNECTION_SUCCESS":
    return {
      ...state,
      loading: false,
      address: action.payload.address,
      reward: action.payload.reward,
      web3: action.payload.web3,
      provider: action.payload.provider,
      connected: action.payload.connected,
      Token:action.payload.Token,
      XDC_AirDrop:action.payload.XDC_AirDrop
    };
  case "CONNECTION_FAILED":
    return {
      ...initialState,
      loading: false,
      errorMsg: action.payload,
    };
  case "UPDATE_ADDRESS":
    return {
      ...state,
      address: action.payload.address,
    };

  default:
    return state;
  }
};

export default walletConnectReducer;
  
  