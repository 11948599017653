import React from "react";
import { AiOutlineDownload } from "react-icons/ai";

function Home() {
  return (
    <div className="">
      <div className="homedadshbg gamedashbg">
        <div className="contentofvideo">
          <video
            loop={true}
            muted={false}
            autoPlay={true}
            id="myVideo1"
            style={{ borderRadius: "1em" }}
            controls={true}
            playsInline={true}
          >
            <source
              src="https://metabloqs-nft.s3-eu-central-1.amazonaws.com/metabloqs/applicationvideos/1669092986200Logo.mp4"
              type="video/mp4"
            />
            <track
              default
              kind="captions"
              srcLang="en"
              src="/media/examples/friday.vtt"
            />
          </video>
          {window.innerWidth > 600 && (
            <div className="center download-build-buttons">
              <button className="download_build-btn m-sm-4 m-2">
                <span className="colored_text">Download for Windows OS</span>
                <AiOutlineDownload size={20} className="ml-2" />
              </button>
              <button className="download_build-btn m-sm-4 m-2">
                <span className="colored_text">Download for Mac OS</span>
                <AiOutlineDownload size={20} className="ml-2" />
              </button>
            </div>
          )}
        </div>
      </div>
      {window.innerWidth < 600 && (
        <div className="center download-build-buttons mt-3">
          <button className="download_build-btn m-sm-4 m-2">
            <span className="colored_text text-dark">Download for Windows OS</span>
            <AiOutlineDownload size={20} className="ml-2" color="black"/>
          </button>
          <button className="download_build-btn m-sm-4 m-2">
            <span className="colored_text text-dark">Download for Mac OS</span>
            <AiOutlineDownload size={20} className="ml-2" color="black"/>
          </button>
        </div>
      )}
    </div>
  );
}

export default Home;
