import React from "react";
import Fade from "react-reveal/Fade";

function Ecosystem() {
  return (
    <section className="press-releasemain">
      <div className="container">
        <div className="ecoinn" id="ecosystem">
          <h2 className="font-weight-bold">Ecosystem</h2>
          <div className="container">
            <ul className="partner-list">
              <i></i>
              <Fade bottom>
                <li>
                  <img
                    src={
                      require("../../assets/images/ecosystem/partner-18.png")
                        .default
                    }
                    alt="img"
                    className="mobile_fit"
                  />
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <img
                    src={
                      require("../../assets/images/ecosystem/partner-17.png")
                        .default
                    }
                    alt="img"
                    className="mobile_fit"
                  />
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <img
                    src={
                      require("../../assets/images/ecosystem/partner-3.png")
                        .default
                    }
                    alt="img"
                    className="mobile_fit"
                  />
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <img
                    src={
                      require("../../assets/images/ecosystem/partner-12.png")
                        .default
                    }
                    alt="img"
                    className="mobile_fit"
                  />
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <img
                    src={
                      require("../../assets/images/ecosystem/partner-6.png")
                        .default
                    }
                    alt="img"
                    className="mobile_fit"
                  />
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <img
                    src={
                      require("../../assets/images/ecosystem/partner-7.png")
                        .default
                    }
                    alt="img"
                    className="mobile_fit"
                  />
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <img
                    src={
                      require("../../assets/images/ecosystem/partner-8.png")
                        .default
                    }
                    alt="img"
                    className="mobile_fit"
                  />
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <img
                    src={
                      require("../../assets/images/ecosystem/partner-9.png")
                        .default
                    }
                    alt="img"
                    className="mobile_fit"
                  />
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <img
                    src={
                      require("../../assets/images/ecosystem/partner-14.png")
                        .default
                    }
                    alt="img"
                    className="mobile_fit"
                  />
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <img
                    src={
                      require("../../assets/images/ecosystem/partner-11.png")
                        .default
                    }
                    alt="img"
                    className="mobile_fit"
                  />
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <img
                    src={
                      require("../../assets/images/ecosystem/partner-16.png")
                        .default
                    }
                    alt="img"
                    className="mobile_fit"
                  />
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <img
                    src={
                      require("../../assets/images/ecosystem/partner-2.png")
                        .default
                    }
                    alt="img"
                    className="mobile_fit"
                  />
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <img
                    src={
                      require("../../assets/images/ecosystem/partner-13.png")
                        .default
                    }
                    alt="img"
                    className="mobile_fit"
                  />
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <img
                    src={
                      require("../../assets/images/ecosystem/partner-1.png")
                        .default
                    }
                    alt="img"
                    className="mobile_fit"
                  />
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <img
                    src={
                      require("../../assets/images/ecosystem/jelmoli.png")
                        .default
                    }
                    alt="img"
                    className="mobile_fit"
                  />
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <img
                    src={
                      require("../../assets/images/ecosystem/gfm.png").default
                    }
                    alt="img"
                    className="mobile_fit"
                  />
                </li>
              </Fade>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Ecosystem;
