import React from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";

const navigation = require("../../assets/images/navigation.png").default;

function Home() {
  const navigate = useNavigate();
  const UserToken = localStorage.getItem("UserToken");

  function scrollFunction() {
    const offset = document.documentElement.scrollTop;
    if (offset < 600) {
      document
        .getElementById("bacground_item")
        ?.style.setProperty(
          "transform",
          "scale(" + (100 - offset / 20) / 100 + ")"
        );
    }
  }
  window.onscroll = function () {
    scrollFunction();
  };
  return (
    <div className="homevid" id="bacground_item">
      <div className="video-container d-flex justify-content-center align-items-center">
        <video
          loop={true}
          muted={true}
          autoPlay={true}
          controls={false}
          playsInline={true}
          id="myVideo"
        >
          <source
            src="https://metabloqs-nft.s3-eu-central-1.amazonaws.com/metabloqs/applicationvideos/1669092986200Logo.mp4"
            type="video/mp4"
          />
          <track
            default
            kind="captions"
            srcLang="en"
            src="/media/examples/friday.vtt"
          />
        </video>

        <div className="callout">
          <Fade bottom>
            <h2>Real People, Real Place, Real Value</h2>
          </Fade>
          <Fade bottom>
            <p>
              With realistic graphics, real-world locations, and true
              identities, <br /> we’re building a lifelike Web 3.0 metaverse for
              genuine interactions <br /> and value. Join now and we will notify
              you when we go live.
            </p>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default Home;
