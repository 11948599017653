import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import { Col, Row, Modal } from "react-bootstrap";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import Bounce from "react-reveal/Bounce";

const navigation = require("../../assets/images/navigation.png").default;
const teamMembers = [
  {
    name: "Megha Shrestha",
    designation: "Founder & CEO",
    image: require("../../assets/images/team/Megha-Shrestha.jpg").default,
    linkdinUrl: "https://www.linkedin.com/in/megha-shrestha-8376342b",
    description:
      "Megha Shrestha, MBA, Msc. in Information Systems has substantial experience in networking events organization. She has worked extensively in project developments from scratch in tertiary education and IT projects and business development in Asia & Middle East",
  },

  {
    name: "Dr. Marc-André Schauwecker",
    designation: "Chairman",
    image: require("../../assets/images/team/marc-andre.jpg").default,
    linkdinUrl: "https://www.linkedin.com/mwlite/in/marc-andré-schauwecker",
    description:
      "Head Legal and Compliance, Conference Organizer and Speaker, President and Chief Strategy, Executive Board Member of Swiss Association of MBAs and President of eTrain",
  },

  {
    name: "André Siegert",
    designation: "CFO",
    image: require("../../assets/images/team/Andre-Siegert.jpg").default,
    linkdinUrl: "https://www.linkedin.com/in/andresiegert/",
    description:
      "André Siegert, MBA is a creative innovator with almost 15 years' experience leading strategy and change initiatives in Finance and cross-departmentally.",
  },
  {
    name: "German Ramirez",
    designation: "Digital, Social Media & Blockchain Pioneer",
    image: require("../../assets/images/team/german-ramirez.jpg").default,
    linkdinUrl: "https://www.linkedin.com/in/gerramirez/",
    description:
      "Disruptive technology entrepreneur and blockchain pioneer with over 25 years of experience in branding, marketing, communications, business development and start-ups. Co-founder of one of Switzerland’s first blockchain start-ups in 2013. 50+ crypto projects. ",
  },

  {
    name: "Nishant Shrestha",
    designation: "Retail & Real Estate Expert",
    image: require("../../assets/images/team/nishant-shrestha.jpg").default,
    linkdinUrl: "https://www.linkedin.com/in/nishantprabhatshrestha/",
    description:
      "Nishant is an expert in Retail Management and Hospitality. He specializes in Retail, Real estate and Mall Management.",
  },

  {
    name: "Isha Vaidya",
    designation: "Head Operations",
    image: require("../../assets/images/team/Isha-Vaidya.jpg").default,
    linkdinUrl: "https://www.linkedin.com/in/isha-vaidya-44762290/",
    description:
      "Isha Vaidya, MBA, is an expert in event management, operations and quality management.",
  },

  {
    name: "Ankush Sharma",
    designation: "Immersive Reality Expert",
    image: require("../../assets/images/team/ankush.jpg").default,
    linkdinUrl: "https://in.linkedin.com/in/ankush-sharma-689072ba/",
    description:
      "Experienced professional with a demonstrated history of working in various industry. Strong business development professional skilled in Management, Customer Service, Strategic Planning, Augmented ,Virtual , Mixed Reality and Marketing Strategy.",
  },
  {
    name: "Goutam Bakshi",
    designation: "Core Team Member",
    image: require("../../assets/images/team/goutam-bakshi.jpg").default,
    linkdinUrl: "http://linkedin.com/in/goutam-bakshi-652a9a3",
    description:
      "Goutam is a ditstributed and P2P computing enthusiast. He has been working in Blockchain related technology for last 5 years, architecting DLT solutions in XinFin XdPOS network, Ethereum and Hyperledger network, with IPFS as Distributed File System.Some of his successful implementations include Private permissioned SME Invoice Factoring in Heyperledger Fabric, MultiSig Wallet & Asset Tokenization solutions in XinFin network.Goutam also has a deep interest in tokenomics, specifically in NFT/Semifungible utility tokens, Stable coins and  DAO.Goutam is currently a Senior member in Yodaplus Blockchain Experts.",
  },
];

const advisors = [
  {
    name: "Prof. Dr. Leo Brecht",
    designation: "Strategy and Innovation Expert",
    image: require("../../assets/images/team/Prof-Leo-Brecht.jpg").default,
    linkdinUrl: "https://www.linkedin.com/in/prof-dr-leo-brecht-a10519a2",
    description:
      "Prof. Dr. Leo Brecht (Strategy and Innovation Expert)is an innovation and startup strategy expert.",
  },
  {
    name: "Dr. Patrik Gisel",
    designation: "Finance Expert",
    image: require("../../assets/images/team/Dr-Patrik-Gisel.jpg").default,
    linkdinUrl: "https://www.linkedin.com/in/patrik-gisel-555aa22/",
    description:
      "Dr. Patrik Gisel is an expert in Finance and Strategy former CEO of Bank Raiffeisen",
  },
  {
    name: "Dr. Julien Weissenberg",
    designation: "AI Expert",
    image: require("../../assets/images/team/Dr-Julien-Weissenberg.jpg")
      .default,
    linkdinUrl: "https://www.linkedin.com/in/julienweissenberg/",
    description:
      "Dr. Julien Weissenberg, PhD., Msc. Advanced computing is an expert of Artificial Intelligence and Founder of VisualSense AI advisory. With 10 years of expertise in AI algorithms, he has been helping build and evaluate AI solutions for a wide array of industries, ranging from agriculture to insurance.",
  },
  {
    name: "Dr. Guenter Dobrauz-Saldapenna",
    designation: "Legal and sustainability expert",
    image: require("../../assets/images/team/Dr-Guenter-Dobrauz-Saldapenna.jpg")
      .default,
    linkdinUrl: "https://www.linkedin.com/in/dobrauz/",
    description:
      "Dr. Guenter Dobrauz-Saldapenna, MBA is a Partner at PwC in Zurich and leads PwC Legal in Switzerland. He is also a co-founding Investor of exelixis capital AG investing in circularity and exponential technologies.",
  },
  {
    name: "Viktor Larionov",
    designation: "Blockchain Economics Expert",
    image: require("../../assets/images/team/viktor-larionov.jpg").default,
    linkdinUrl: "https://www.linkedin.com/in/viktor-larionov-2324731/",
    description:
      "Co-founder and CEO at the longest standing blockchain advisory firm Priority Token (ptoken.io) and Industry 4.0 Investment Bank (industry4ib.com) - revolutionizing global fintech & blockchain industry.Investor in numerous crypto projects. Partner in one of the biggest Australian crypto fund UniRock Partners.Advisor for a number of disruptive blockchain projects, including Credefi, Immunify, Guardians of Blockchain. Personally supervising off-market investment opportunities in tech industry and dealing with fellow investors (active network of 1000+ private and institutional investors).15+ year background with major VCs in Singapore, Hong Kong and London. Currently based between Melbourne and London with other important bases in Zurich, Milan, London, Dubai, Seoul, Tokyo, Hong Kong and Singapore.",
  },
];

function Team() {
  const { width } = useWindowDimensions();
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const UserToken = localStorage.getItem("UserToken");
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);
    setModalData(item);
  };
  return (
    <section className="our-team" id="team">
      <div className="container">
        <div className="section-title text-center teamheading">
          <h4 className="font-weight-bold">Meet the Team</h4>
          <p>
            Our team combines experts in the fields of finance and business
            development, <br /> and pioneers from the Web3 and digital asset
            sector.
          </p>
        </div>
        <Swiper
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          slidesPerView={width < 900 ? 2 : 5}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
        >
          {teamMembers.map((item, i) => (
            <SwiperSlide
              className="member-box"
              key={i}
              onClick={() => handleShow(item)}
            >
              <figure>
                <img src={item.image} alt="memberteam" />
              </figure>
              <figcaption>
                <div className="member-boxdown">
                  <h4>{item.name}</h4>
                  <h5>{item.designation}</h5>
                </div>
                <span className="left-arrow">
                  <img
                    src="https://static.metabloqs.com/web/assets/images/icons/left-arrow-black.svg"
                    alt="img"
                  />
                </span>
              </figcaption>
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          slidesPerView={width < 900 ? 2 : 5}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
        >
          {advisors.map((item, i) => (
            <SwiperSlide
              className="member-box"
              key={i}
              onClick={() => handleShow(item)}
            >
              <figure>
                <img src={item.image} alt="memberteam" />
              </figure>
              <figcaption>
                <div className="member-boxdown">
                  <h4>{item.name}</h4>
                  <h5>{item.designation}</h5>
                </div>
                <span className="left-arrow">
                  <img
                    src="https://static.metabloqs.com/web/assets/images/icons/left-arrow-black.svg"
                    alt="img"
                  />
                </span>
              </figcaption>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="bluebox">
        <div className="container">
          <div className="blueboxinn" style={{ zIndex: 99 }}>
            <div className="row">
              <div className="col-md-12">
                <div
                  className={
                    width < 600
                      ? "blu justify-content-between align-items-center"
                      : "blu d-flex justify-content-between align-items-center"
                  }
                >
                  <h3 className="font-weight-bold">
                    {" "}
                    We are Waiting for You!{" "}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Row
          className="p-3"
          style={{ height: "auto", overflow: "hidden", borderRadius: "1em" }}
        >
          <Col xxl={6} xl={6} lg={6} md={8} sm={10} xs={10} className="h-100">
            <div style={{ height: "250px", width: "100%" }}>
              <img
                src={modalData.image}
                alt="memberteam"
                height={"100%"}
                width={"100%"}
                style={{ objectFit: "cover" }}
              />
            </div>
          </Col>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={8}
            sm={10}
            xs={10}
            className="pl-0 h-100"
            style={{overflow:"auto"}}
          >
            <div className="member-boxdown" style={{minHeight:"250px"}}>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h4>{modalData.name}</h4>
                <a target="blank" href={modalData.linkdinUrl}>
                  <img
                    src="https://static.metabloqs.com/web/assets/images/icons/linkedin-icon.png"
                    alt="img"
                    height={40}
                    width={40}
                  />
                </a>
              </div>
              <h5>{modalData.designation}</h5>
              <h5>{modalData.description}</h5>
            </div>
          </Col>
        </Row>
      </Modal>
    </section>
  );
}

export default Team;
