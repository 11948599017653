import React, { useEffect, useState } from "react";
// import {Image} from "react-bootstrap"
import Atlas from "../../../components/atlas"
// import { TileMap } from "react-tile-map";
function Map() {
  const [Parcels, setParcels] = useState([])
  const filterType =""
  const filterTypeValue =""
  const [setSelectedAxis,onSelectGrid] =useState([])
  useEffect(() => {
    getdata()
  }, [])

  const getdata = async () => {
    // setAtlasLoader(true);
    var jsonData = "https://metabloqs-nft.s3-eu-central-1.amazonaws.com/metabloqs-metaverse/nft/1684484080240Land.json"
    try {
      const res = await fetch(jsonData, { cache: "no-store" });
      const json = await res.json();
      // setAtlasLoader(false);
      if (json.ok) {
        setParcels(json.data);
      }
    } catch (err) {
      console.log("Error1", err)
    }
  };
  
  return (
    <div className="mapcomtop">
      <div className="row">
        <div className="col-lg-12">
          <Atlas
            onSale={false}
            selectedParcels={Parcels}
            parcels={Parcels}
            filterType={filterType}
            filterTypeValue={filterTypeValue}
            setSelectedAxis={setSelectedAxis}
            onSelectGrid={onSelectGrid}
          />
        </div>
      </div>
    </div>
  );
}

export default Map;
