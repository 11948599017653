import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#4b8cff",
    },
    secondary: {
      main: "#ff6420",
    },
    disabledColor: {
      main: "#fff",
    },
    tertiary: {
      main: "#000",
    },
    error: {
      main: "#cf3e34",
    },
    success: {
      main: "#36c956",
    },
  },
});
