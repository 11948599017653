import React from "react";
import "./Differences.css";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";

const navigation = require("../../assets/images/navigation.png").default;

function Differences() {
  const UserToken = localStorage.getItem("UserToken");
  const navigate = useNavigate();

  return (
    <section className="technology">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="buildheading mb-5">
              <h1>What’s different about Metabloqs?</h1>
              <div className="text-center">
                <span>We trust what’s real.</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <Fade bottom>
              <div className="hover hover-2 text-white hover-sky">
                <img
                  src={
                    require("../../assets/images/tabs/img8.png").default
                  }
                  alt="img"
                  className="hover_difference"
                  style={{ height: "20em",objectFit:"cover" }}
                />
                <div className="hover-overlay"></div>
                <div className="hover-2-content">
                  <h3 className="hover-2-title font-weight-bold mb-2">
                    Real Places
                  </h3>
                  <p className="my-1 font-weight-lighter">
                    Metabloqs is based on familiar, real-world cities that <br/> will serve as the canvas for a metaverse that is grounded in reality. We’re starting with one of the most iconic of all: Paris.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
          <div className="col-lg-6">
            <Fade bottom>
              <div className="hover hover-2 text-white hover-sky">
                <img
                  src={
                    require("../../assets/images/difference/img4.png").default
                  }
                  alt="img"
                  className="hover_difference"
                  style={{ height: "20em",objectFit:"cover" }}
                />
                <div className="hover-overlay"></div>
                <div className="hover-2-content">
                  <h3 className="hover-2-title font-weight-bold mb-2">
                    Real People
                  </h3>
                  <p className="my-1 font-weight-lighter">
                  We encourage users to use their real names and are <br/> establishing transparent community guidelines to help create a more welcoming, civic culture.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
          <div className="col-lg-6">
            <Fade bottom>
              <div className="hover hover-2 text-white hover-sky">
                <img
                  src={
                    require("../../assets/images/tabs/img5.png").default
                  }
                  alt="img"
                  className="hover_difference"
                  style={{ height: "20em",objectFit:"cover" }}
                />
                <div className="hover-overlay"></div>
                <div className="hover-2-content">
                  <h3 className="hover-2-title font-weight-bold mb-2">
                    Real Value
                  </h3>
                  <p className="my-1 font-weight-lighter">
                  No play-to-earn gimmicks: From networking and events, <br/> to leisure and commerce — we focus on authentic experiences that have intrinsic value.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
          <div className="col-lg-6">
            <Fade bottom>
              <div className="hover hover-2 text-white hover-sky">
                <img
                  src={
                    require("../../assets/images/tabs/img2.png").default
                  }
                  alt="img"
                  className="hover_difference"
                  style={{ height: "20em",objectFit:"cover" }}
                />
                <div className="hover-overlay"></div>
                <div className="hover-2-content">
                  <h3 className="hover-2-title font-weight-bold mb-2">
                    Real Ownership
                  </h3>
                  <p className="my-1 font-weight-lighter">
                  The ownership of the BLOQS token, as well as all land <br/> and NFTs are stored and secured on a decentralized blockchain network.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Differences;
