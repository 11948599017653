import React from "react";
import { useNavigate } from "react-router-dom";
import Differences from "./differences/Differences";
import Ecosystem from "./ecosystem/Ecosystem";
import Explorer from "./explorer/Explorer";
import Home from "./home/Home";
import Launch from "./launch/Launch";
import Meta from "./meta/Meta";
import Partner from "./partner/Partner";
import PressRelease from "./preRelease/PressRelease";
import Team from "./team/Team";

function Main() {
  const navigate = useNavigate();
  return (
    <>
      <Home />
      <Differences />
      <Explorer />
      <Partner />
      <Meta />
      <Launch />
      <PressRelease/>
      <Ecosystem />
      <Team />
    </>
  );
}

export default Main;
