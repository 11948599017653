import React, { useState, useEffect } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import "./style.css";
import { RiUploadCloudFill } from "react-icons/ri";
import {
  AiOutlineWhatsApp,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiOutlineFacebook,
} from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { postMethod, ReactS3Client } from "../../../helpers";

function EditProfile() {
  const navigate = useNavigate();
  const UserWallet = localStorage.getItem("UserWallet");
  const UserEmail = localStorage.getItem("UserEmail");
  const user = JSON.parse(localStorage.getItem("User"));
  const placeholder =
    require("../../../assets/images/profile_placeholder.png").default;

  const [Facebooklink, setFacebooklink] = useState("");
  const [Instagramlink, setInstagramlink] = useState("");
  const [Twitterlink, setTwitterlink] = useState("");
  const [Telegramlink, setTelegramlink] = useState("");
  const [Whatsapplink, setWhatsapplink] = useState("");
  // const [Profile, setProfile] = useState();
  const [email, setEmail] = useState(UserEmail);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setusername] = useState("");

  //eslint-disable-next-line
  const [profileImg, setProfileImg] = useState(user.profile_img);
  const [bannerImg, setBannerImg] = useState(user.banner_img);

  const banner_placeholder =
    require("../../../assets/images/banner-placeholder.jpg").default;

  const updateProfileImg = async (e) => {
    var file = e.target.files[0];
    var timeStamp = Date.now();
    let name = file.name;
    let extension = "." + name.split(".").pop();
    let filename = timeStamp + extension;
    try {
      const data = await ReactS3Client.uploadFile(file, filename);
      var user = JSON.parse(localStorage.getItem("User"));
      user.profile_img = data.location;
      localStorage.setItem("User", JSON.stringify(user));
      setProfileImg(data.location);
      if (UserEmail) {
        let url = "updateimage";
        let params = {
          image: data.location,
          email: UserEmail,
        };
        let authtoken = "";
        let response = await postMethod({ url, params, authtoken });
      }
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  const updateBannerImg = async (e) => {
    var file = e.target.files[0];
    var timeStamp = Date.now();
    let name = file.name;
    let extension = "." + name.split(".").pop();
    let filename = timeStamp + extension;
    try {
      const data = await ReactS3Client.uploadFile(file, filename);
      var user = JSON.parse(localStorage.getItem("User"));
      user.banner_img = data.location;
      localStorage.setItem("User", JSON.stringify(user));
      setBannerImg(data.location);
      if (UserEmail) {
        let url = "updatebannerimage";
        let params = {
          image: data.location,
          email: UserEmail,
        };
        let authtoken = "";
        let response = await postMethod({ url, params, authtoken });
      }
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  const userprofileupdate = async () => {
    try {
      let url = "updateuserprofile";
      let params = {
        email: UserEmail,
        facebooklink: Facebooklink,
        twitterlink: Twitterlink,
        telegramlink: Telegramlink,
        instagramlink: Instagramlink,
        whatsapplink: Whatsapplink,
        firstname: firstname,
        lastname: lastname,
        username: username
      };
      let authtoken = "";
      let response = await postMethod({ url, params, authtoken });
      if (response.status == true) {
        var user = JSON.parse(localStorage.getItem("User"));
        user.facebooklink = Facebooklink;
        user.user_name = username;
        user.twitterlink = Twitterlink;
        user.telegramlink = Telegramlink;
        user.instagramlink = Instagramlink;
        user.whatsapplink = Whatsapplink;
        user.first_name = firstname;
        user.last_name = lastname;
        localStorage.setItem("User", JSON.stringify(user));
        localStorage.setItem("UserName",username);
        navigate("/sidebar/profile");
      }
    } catch (e) {
      console.log("err", e);
    }
  };

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("User"));
    if (user) {
      setEmail(UserEmail);
      if(user.first_name!=null && user.first_name!=undefined && user.first_name!="undefined"&& user.first_name!="null"){
        setFirstname(user.first_name);
      }
      if(user.user_name!=null && user.user_name!=undefined && user.user_name!="undefined"&& user.user_name!="null"){
        setusername(user.user_name);
      }
      if(user.last_name!=null && user.last_name!=undefined && user.last_name!="undefined"&& user.last_name!="null"){
        setLastname(user.last_name);
      }
      if(user.facebooklink!=null && user.facebooklink!=undefined && user.facebooklink!="undefined"&& user.facebooklink!="null"){
        setFacebooklink(user.facebooklink);
      }
      if(user.telegramlink!=null && user.telegramlink!=undefined && user.telegramlink!="undefined"&& user.telegramlink!="null"){
        setTelegramlink(user.telegramlink);
      }
      if(user.twitterlink!=null && user.twitterlink!=undefined && user.twitterlink!="undefined"&& user.twitterlink!="null"){
        setTwitterlink(user.twitterlink);
      }
      if(user.instagramlink!=null && user.instagramlink!=undefined && user.instagramlink!="undefined"&& user.instagramlink!="null"){
        setInstagramlink(user.instagramlink);
      }
      if(user.whatsapplink!=null && user.whatsapplink!=undefined && user.whatsapplink!="undefined"&& user.whatsapplink!="null"){
        setWhatsapplink(user.whatsapplink);
      }
      
      if (user.profile_img) {
        setProfileImg(user.profile_img);
      }
    }
  }, []);

  return (
    <div className="Edit-profile">
      <Stack gap={3}>
        <div className="d-flex justify-content-between align-items-center">
          <BsArrowLeft size={30} color="black" onClick={() => navigate(-1)} />
          <h4 className="m-0 font-weight-bold">Edit Profile</h4>
        </div>
        <div className="profile_bannner">
          <div className="banner_img-section">
            <img
              src={bannerImg == null ? banner_placeholder : bannerImg}
              alt="img"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius: "1em 1em 0 0",
              }}
            />
          </div>
          <div className="banner_content-section-edit py-3">
            <label className="createitem_upload-button">
              <RiUploadCloudFill color="white" /> <small>Upload</small>
              <input
                type="file"
                style={{ display: "none" }}
                onChange={updateBannerImg}
              />
            </label>
          </div>
        </div>
        <h4 className="font-weight-bold">General</h4>
        <Row>
          <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
            <div className="edit-profile-img ">
              <div className="img-box h-75 bg-light">
                <img
                  src={profileImg == null ? placeholder : profileImg}
                  alt="img"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: ".5em",
                  }}
                />
              </div>
              <div className="d-flex h-25 justify-content-center align-items-center">
                <label className="createitem_upload-button mb-0">
                  <RiUploadCloudFill color="white" /> <small>Upload</small>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={updateProfileImg}
                  />
                </label>
              </div>
            </div>
          </Col>
          <Col xxl={9} xl={9} lg={9} md={6} sm={12} xs={12}>
            <div className="edit-profile-content">
              <Row>
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <h6>User Name</h6>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="User Name"
                      value={username}
                      onChange={(e) => setusername(e.target.value)}
                      style={{ color: "gray" }}
                    />
                  </div>
                  <h6>First Name</h6>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                      style={{ color: "gray" }}
                    />
                  </div>

                  <h6>Wallet Address</h6>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Wallet Address"
                      value={UserWallet}
                      style={{ color: "gray" }}
                      disabled
                    />
                  </div>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <h6>Email</h6>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ color: "gray" }}
                      disabled
                    />
                  </div>
                  <h6>Last Name</h6>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      style={{ color: "gray" }}
                    />
                  </div>
                  <h6>Country</h6>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Country"
                      style={{ color: "gray" }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <h4 className="font-weight-bold">Social</h4>
        <Row>
          <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
            <h6>Facebook</h6>
            <div className="form-group d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Facebook Link"
                value={Facebooklink}
                onChange={(e) => setFacebooklink(e.target.value)}
              />
              <div className="social-icon-box">
                <AiOutlineFacebook size={18} color="white" />
              </div>
            </div>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
            <h6>Instagram</h6>
            <div className="form-group d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Instagram Link"
                value={Instagramlink}
                onChange={(e) => setInstagramlink(e.target.value)}
              />
              <div className="social-icon-box">
                <AiOutlineInstagram size={18} color="white" />
              </div>
            </div>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
            <h6>Twitter</h6>
            <div className="form-group d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Twitter Link"
                value={Twitterlink}
                onChange={(e) => setTwitterlink(e.target.value)}
              />
              <div className="social-icon-box">
                <AiOutlineTwitter size={18} color="white" />
              </div>
            </div>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
            <h6>Whatsapp</h6>
            <div className="form-group d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Whatsapp Link"
                value={Whatsapplink}
                onChange={(e) => setWhatsapplink(e.target.value)}
              />
              <div className="social-icon-box">
                <AiOutlineWhatsApp size={18} color="white" />
              </div>
            </div>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
            <h6>Telegram</h6>
            <div className="form-group d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Telegram Link"
                value={Telegramlink}
                onChange={(e) => setTelegramlink(e.target.value)}
              />
              <div className="social-icon-box">
                <FaTelegramPlane size={18} color="white" />
              </div>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-center align-item-center">
          <button className="primary-square-button" onClick={userprofileupdate}>
            Save Profile
          </button>
        </div>
      </Stack>
    </div>
  );
}

export default EditProfile;
