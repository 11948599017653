import React from "react";
import { Col, Image, Row, Stack } from "react-bootstrap";
import "./style.css";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { FormatDate, FormatTime, postMethod, Slicer } from "../../../helpers";
import {
  AiTwotoneCopy,
  AiOutlineWhatsApp,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiOutlineFacebook,
} from "react-icons/ai";
import { InlineShareButtons } from "sharethis-reactjs";
import {
  FaCoins,
  FaTelegramPlane,
  //  FaFacebookMessenger
} from "react-icons/fa";
import { BsPersonFill } from "react-icons/bs";
import { MdVerified } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import BuyBloqsModal from "../../modals/BuyBloqsModal";
import SwapModal from "../../modals/swap";
import {
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import MessageModal from "../../modals/MessageModal";
import RedeemRewards from "../../modals/RedeemRewards";

const urls = process.env.REACT_APP_TRANSACTION_URL;
const bloqs = require("../../../assets/images/logo_block.png").default;
const XDClogo = require("../../../assets/images/xdc-icon.png").default;
const Dollarlogo = require("../../../assets/images/dollar-sign.png").default;

function Profile() {
  const navigate = useNavigate();
  const wallet = useSelector((state) => state.WalletConnect);
  const UserEmail = localStorage.getItem("UserEmail");
  const UserName = localStorage.getItem("UserName");
  const UserToken = localStorage.getItem("UserToken");
  const UserWallet = localStorage.getItem("UserWallet");

  const { address, web3, Token } = wallet;
  const [xdcbal, setxdcbal] = useState("");
  const [bloqsbal, setbloqsbal] = useState("");
  const [referHistory, setReferHistory] = useState([]);
  const [totalReferal, setTotalReferal] = useState(0);
  const [userNft, setUserNft] = useState([]);
  const [buyBloqs, setBuyBloqs] = useState(false);
  const [swap, setSwap] = useState(false);
  const [copySuccess, setCopySuccess] = useState(null);
  const [showElement, setShowElement] = useState(false);
  const [referalLink, setReferalLink] = useState("");
  //eslint-disable-next-line
  const [referalLinkOnly, setreferalLinkOnly] = useState("");
  const [userActivity, setUserActivity] = useState([]);
  const [msgShow, setMsgShow] = useState(false);
  const [message, setMessage] = useState("");
  const [userReward, setUserReward] = useState(0);

  const [redeemModal, setRedeemModal] = useState(false);

  const user = JSON.parse(localStorage.getItem("User"));

  const placeholder =
    require("../../../assets/images/profile_placeholder.png").default;
  const banner_placeholder =
    require("../../../assets/images/banner-placeholder.jpg").default;

  const copyAddress = () => {
    navigator.clipboard.writeText(address);
    setCopySuccess("Copied!");
    setShowElement(true);
  };

  const shareReferalCode = () => {
    var id = user.referral_code;
    var text =
      "I love using Metabloqs, I am sure you will also like, explore here" +
      " " +
      process.env.REACT_APP_LIVE_URL +
      `signup?id=${id}`;
    navigator.clipboard.writeText(text);
    setReferalLink(text);
    setreferalLinkOnly(process.env.REACT_APP_LIVE_URL + `signup?id=${id}`);
  };


  const nftClick = (nftIid, collectionId) => {
    window.open(
      "https://main.diml28wv818sq.amplifyapp.com/" +
      `collectionhome/${collectionId}/${nftIid}`
    );
  };
  const copyReferalCode = () => {
    navigator.clipboard.writeText(user.referral_code);
    setCopySuccess("Copied!");
    setShowElement(true);
  };

  const getXDCBalance = async () => {
    if (address) {
      const balance = await web3?.eth.getBalance(address);
      const etherBalance = await web3?.utils.fromWei(balance, "ether");
      setxdcbal(etherBalance);
    }
  };

  const getBloqsBalance = async () => {
    if (address) {
      const bloqsBalance = await Token?.methods.balanceOf(address).call();
      let bal = web3.utils.fromWei(bloqsBalance, "ether");
      setbloqsbal(bal);
    }
  };

  const getReferalHistory = async () => {
    try {
      let url = "getreferralhistory";
      let params = {
        email: UserEmail,
      };
      let authtoken = UserToken;
      let response = await postMethod({ url, params, authtoken });
      if (response.status == true) {
        var result = response.result.filter(
          (data) => user.referral_code == data.referred_by
        );
        //eslint-disable-next-line
        const dummy = result.map((item) => {
          var count = 0;
          response.result.map((res) => {
            if (item.referral_code == res.referred_by) {
              count = count + 1;
            }
          });
          item.count = count;
        });
        setReferHistory(result);
        setTotalReferal(response.count);
      } else if (response.message == "Unauthorized!") {
        navigate("/login");
        localStorage.clear();
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUserActivity = async () => {
    try {
      let url = "getAllUserActivites";
      let params = {
        wallet: UserWallet,
      };
      let authtoken = UserToken;
      let response = await postMethod({ url, params, authtoken });
      if (response.status == true) {
        setUserActivity(response.result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUserNft = async () => {
    try {
      let url = "getUserNfts";
      let params = {
        wallet: address,
      };
      let authtoken = UserToken;
      let response = await postMethod({
        url,
        params,
        authtoken,
      });
      if (response.status) {
        setUserNft(response.result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUserRewards = async () => {
    try {
      let url = "userRewards";
      let params = {
        email: UserEmail,
      };
      let authtoken = UserToken;
      let response = await postMethod({
        url,
        params,
        authtoken,
      });
      if (response.status) {
        setUserReward(response.rewards);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const CommingSoonalert = async () => {
    setMsgShow(true);
    setMessage("Coming Soon");
  }

  useEffect(() => {
    getXDCBalance();
    getBloqsBalance();
    getReferalHistory();
    getUserActivity();
    shareReferalCode();
    getUserRewards();
    if (wallet.connected) {
      getUserNft();
    }
  }, [wallet.connected, wallet.address]);

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 3000);
  }, [showElement]);

  useEffect(() => {
  }, [referHistory]);

  var formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return (
    <>
      <div className="profile-container">
        <Stack gap={5}>
          <div className="profile_bannner">
            <div className="banner_img-section">
              <img
                src={
                  user.banner_img == null ? banner_placeholder : user.banner_img
                }
                alt="img"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "1em 1em 0 0",
                }}
              />
            </div>
            <div className="banner_content-section">
              <Row className="h-100">
                <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} className="mb-2">
                  <div className="d-sm-flex justify-content-between align-items-center h-100 px-sm-5 px-2">
                    <div className="user_avatar">
                      <img
                        src={
                          user.profile_img == null
                            ? placeholder
                            : user.profile_img
                        }
                        className="avatar_img bg-light"
                        width={100}
                        alt="img"
                      />
                    </div>
                    <div className="">
                      <h5 className="font-weight-bold">{UserName}</h5>
                      <p>{UserEmail}</p>
                    </div>
                    <div className="text-center profile_wallet-mob">
                      <div className="copy_div">
                        <span className="mr-2">
                          {wallet.connected
                            ? Slicer(wallet.address)
                            : "connect wallet"}
                        </span>
                        <AiTwotoneCopy size={18} onClick={copyAddress} />
                      </div>
                      {showElement && copySuccess ? (
                        <small className="text-success text-center">
                          {copySuccess}
                        </small>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
                  <div className="h-100 d-flex justify-content-around align-items-center">
                    <div className="d-flex justify-content-between align-items-center">
                      <a
                        href={
                          user.facebooklink == "null" || undefined
                            ? "#"
                            : user.facebooklink
                        }
                        className="social-icon-box p-2 mr-2"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <AiOutlineFacebook size={18} color="white" />
                      </a>
                      <a
                        href={
                          user.instagramlink == "null" || undefined
                            ? "#"
                            : user.instagramlink
                        }
                        className="social-icon-box p-2 mr-2"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <AiOutlineInstagram size={18} color="white" />
                      </a>
                      <a
                        href={
                          user.whatsapplink == "null" || undefined
                            ? "#"
                            : user.whatsapplink
                        }
                        className="social-icon-box p-2 mr-2"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <AiOutlineWhatsApp size={18} color="white" />
                      </a>
                      <a
                        href={
                          user.twitterlink == "null" || undefined
                            ? "#"
                            : user.twitterlink
                        }
                        className="social-icon-box p-2 mr-2"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <AiOutlineTwitter size={18} color="white" />
                      </a>
                      <a
                        href={
                          user.telegramlink == "null" || undefined
                            ? "#"
                            : user.telegramlink
                        }
                        className="social-icon-box p-2"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <FaTelegramPlane size={18} color="white" />
                      </a>
                    </div>
                    <button
                      className="gradient-button"
                      onClick={() => navigate("editprofile")}
                    >
                      Edit
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="">
            <h4 className="font-weight-bold">My Wallet</h4>
            <Row>
              <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                className="mb-3"
              >
                <div className="h-100 bloqs-box">
                  <img
                    src={require("../../../assets/images/bloqs_bg.png").default}
                    height={60}
                    width={60}
                    className="bg-img"
                    alt="img"
                  />
                  <Stack gap={3}>
                    <h6 className="font-weight-bold">Bloqs</h6>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        {wallet.connected ? (
                          <>
                            <img
                              src={
                                require("../../../assets/images/bloqs.png")
                                  .default
                              }
                              height={25}
                              width={25}
                              alt="img"
                            />
                            <h5 className="font-weight-bold mb-0 ml-2">
                              {formatter.format(bloqsbal)}
                            </h5>
                          </>
                        ) : (
                          "connect wallet"
                        )}
                      </div>
                      <button
                        // disabled
                        className="primary-button "
                        style={{ padding: ".4em 2em", zIndex: 1 }}
                        // onClick={() => {
                        //   if (!wallet.connected) {
                        //     setMsgShow(true);
                        //     setMessage("Please Connect Wallet");
                        //   } else if (bloqsbal == 0) {
                        //     setMsgShow(true);
                        //     setMessage("Insufficient Bloqs Balance");
                        //   } else {
                        //     setBuyBloqs(true);
                        //   }
                        // }}
                        onClick={CommingSoonalert}
                      >
                        Buy
                      </button>
                    </div>
                  </Stack>
                </div>
              </Col>
              <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                className="mb-3"
              >
                <div className="h-100 xdc-box">
                  <img
                    src={require("../../../assets/images/xdc_bg.png").default}
                    className="bg-img"
                    alt="img"
                    height={60}
                    width={60}
                  />
                  <Stack gap={3}>
                    <h6 className="font-weight-bold">XDC</h6>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        {wallet.connected ? (
                          <>
                            <img
                              src={
                                require("../../../assets/images/xdc.png")
                                  .default
                              }
                              height={25}
                              width={25}
                              alt="img"
                            />
                            <h5 className="font-weight-bold mb-0 ml-2">
                              {formatter.format(xdcbal)}
                            </h5>
                          </>
                        ) : (
                          "connect wallet"
                        )}
                      </div>
                      <button
                        //disabled
                        className="primary-button "
                        style={{ padding: ".4em 2em", zIndex: 1 }}
                        onClick={CommingSoonalert}
                      // onClick={() => {
                      //   if (!wallet.connected) {
                      //     setMsgShow(true);
                      //     setMessage("Please Connect Wallet");
                      //   } else if (xdcbal == 0) {
                      //     setMsgShow(true);
                      //     setMessage("Insufficient XDC Balance");
                      //   } else {
                      //     setSwap(true);
                      //   }
                      // }}
                      >
                        Swap
                      </button>
                    </div>
                  </Stack>
                </div>
              </Col>
              <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                className="mb-3"
              >
                <div className="h-100 bonus-box">
                  <Stack gap={3}>
                    <h6 className="font-weight-bold">Reward Bonus</h6>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        {wallet.connected ? (
                          <h5 className="font-weight-bold mb-0 ml-2">
                            {formatter.format(userReward)} Points
                          </h5>
                        ) : (
                          "connect wallet"
                        )}
                      </div>
                      <button
                        className="primary-button "
                        style={{ padding: ".4em 2em", zIndex: 1 }}
                        // onClick={redeemRewards}
                        onClick={CommingSoonalert}
                      >
                        Redeem
                      </button>
                    </div>
                  </Stack>
                </div>
              </Col>
            </Row>
          </div>
          <div className="refer_box">
            <Row>
              <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                <div className="h-100 d-flex justify-content-center align-items-center">
                  <img
                    src={
                      require("../../../assets/images/refer_img.png").default
                    }
                    style={{ width: 270, height: 270 }}
                    alt="img"
                  />
                </div>
              </Col>
              <Col
                xxl={8}
                xl={8}
                lg={8}
                md={8}
                sm={12}
                xs={12}
                className="pr-5"
              >
                <div className="h-100 py-5">
                  <h2 className="text-light font-weight-bold">
                    {" "}
                    Refer and Earn
                  </h2>
                  <small className="text-light">
                    Refer your friends and get 5 Reward points for every
                    successful registration and also your <br /> friend will
                    get5 rward points{" "}
                  </small>
                  <Row className="text-light mt-3">
                    <Col
                      xxl={6}
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="mb-3"
                    >
                      <div className="h-100 reward-boxes">
                        <div className="d-flex">
                          <FaCoins color="white" size={20} />
                          <h6 className="mb-0 ml-2">Total Reward Points</h6>
                        </div>
                        <h6 className="mb-0">{formatter.format(userReward)}</h6>
                      </div>
                    </Col>
                    <Col
                      xxl={6}
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="mb-3"
                    >
                      <div className="h-100 reward-boxes">
                        <div className="d-flex">
                          <BsPersonFill color="white" size={20} />
                          <h6 className="mb-0 ml-2">Total Referrals</h6>
                        </div>
                        <h6 className="mb-0">{totalReferal}</h6>
                      </div>
                    </Col>
                  </Row>
                  <div className="social_share mt-3">
                    <small className="text-light">
                      Share your referral code
                    </small>
                    <Row className="mt-2">
                      <Col
                        xxl={6}
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className="text-center mb-3"
                      >
                        <div className="copy_refer-code text-dark">
                          {user.referral_code}
                          <AiTwotoneCopy size={18} onClick={copyReferalCode} />
                        </div>
                        {showElement && copySuccess ? (
                          <small
                            className="text-light text-center"
                            style={{ position: "absolute", left: 0, right: 0 }}
                          >
                            {copySuccess}
                          </small>
                        ) : null}
                      </Col>
                      <Col
                        xxl={6}
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className="mb-3"
                      >
                        <div className="d-flex justify-content-between pr-5">
                          {/* <div className="social-icon-box" role="presentation">
                            <FacebookMessengerShareButton url={referalLinkOnly}>
                              <FaFacebookMessenger size={18} color="white" />
                            </FacebookMessengerShareButton>
                          </div> */}
                          <div className="social-icon-box" role="presentation">
                            <InlineShareButtons
                              config={{
                                alignment: "center", // alignment of buttons (left, center, right)
                                color: "social", // set the color of buttons (social, white)
                                enabled: true, // show/hide buttons (true, false)
                                font_size: 10, // font size for the buttons
                                labels: "cta", // button labels (cta, counts, null)
                                language: "en", // which language to use (see LANGUAGES)
                                networks: ["messenger"],
                                padding: 0, // padding within buttons (INTEGER)
                                radius: 4, // the corner radius on each button (INTEGER)
                                show_total: false,
                                size: 30, // the size of each button (INTEGER)

                                url: `https://main.d2e2yykkt8lyd0.amplifyapp.com/signup?id=${user.referral_code}`, // (defaults to current url)
                                image: "https://bit.ly/2CMhCMC", // (defaults to og:image or twitter:image)
                                description:
                                  "I love using Metabloqs, I am sure you will also like, explore here", // (defaults to og:description or twitter:description)
                                title:
                                  "I love using Metabloqs, I am sure you will also like, explore here", // (defaults to og:title or twitter:title)
                                message:
                                  "I love using Metabloqs, I am sure you will also like, explore here", // (only for email sharing)
                                subject:
                                  "I love using Metabloqs, I am sure you will also like, explore here", // (only for email sharing)
                                username:
                                  "I love using Metabloqs, I am sure you will also like, explore here", // (only for twitter sharing)
                              }}
                              style={{ backgroundColor: "red !important" }}
                            />
                          </div>
                          <div className="social-icon-box" role="presentation">
                            <WhatsappShareButton url={referalLink}>
                              <AiOutlineWhatsApp size={18} color="white" />
                            </WhatsappShareButton>
                          </div>
                          <div className="social-icon-box" role="presentation">
                            <TwitterShareButton url={referalLink}>
                              <AiOutlineTwitter size={18} color="white" />
                            </TwitterShareButton>
                          </div>
                          <div className="social-icon-box" role="presentation">
                            <TelegramShareButton url={referalLink}>
                              <FaTelegramPlane size={18} color="white" />
                            </TelegramShareButton>
                          </div>
                          <div className="social-icon-box" role="presentation">
                            <EmailShareButton
                              url={referalLinkOnly}
                              subject={"Metabloqs Referral Link"}
                              body={
                                "I love using Metabloqs, I am sure you will also like, explore here"
                              }
                            >
                              <HiOutlineMail size={18} color="white" />
                            </EmailShareButton>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <h4 className="font-weight-bold">Referral History</h4>
            <Row
              className="refer-table-head text-left fs-17"
              style={{
                width: window.innerWidth < 600 && "900px",
                overflow: "auto",
              }}
            >
              <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                No
              </Col>
              <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                User
              </Col>
              <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                Referred Users
              </Col>
              <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                Points
              </Col>
              <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                Date & Time
              </Col>
            </Row>
            <div className="outer-table-wrapper">
              <div className="table-referral">
                {referHistory.length > 0 ? (
                  referHistory.map((data, index) => (
                    <Row
                      className="refer-table-body text-left pl-3"
                      style={{ fontSize: "15px" }}
                      key={index}
                    >
                      <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                        {index + 1}
                      </Col>
                      <Col
                        xxl={3}
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        className="d-flex justify-content-start align-items-center"
                      >
                        {data.user_name}{" "}
                        {data.user_status == "active" && (
                          <MdVerified
                            color="#1C83E5"
                            size={22}
                            className="pl-2"
                          />
                        )}
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="pl-5"
                      >
                        {data.count}
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="pl-4"
                      >
                        <span className="text-success">{5}</span>
                      </Col>
                      <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                        {FormatDate(data.user_updated_at)},{" "}
                        {FormatTime(data.user_updated_at)}
                      </Col>
                    </Row>
                  ))
                ) : (
                  <p className="text-center">No Referral History</p>
                )}
              </div>
            </div>
          </div>
          <div>
            <h4 className="font-weight-bold">Activity History</h4>
            <Row
              className="refer-table-head text-left fs-17"
              style={{
                width: window.innerWidth < 600 && "900px",
                overflow: "auto",
              }}
            >
              <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                Activity
              </Col>
              <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                Hash
              </Col>
              <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                From
              </Col>
              <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                To
              </Col>
              <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                Date & Time
              </Col>
            </Row>
            <div className="outer-table-wrapper">
              <div className="table-referral">
                {userActivity.length > 0 ? (
                  userActivity
                    .filter(
                      (data) =>
                        data.activities_type == "Claim Rewards" ||
                        data.activities_type == "buybloqs" ||
                        data.activities_type == "swap"
                    )
                    .map((data, index) => (
                      <Row
                        className="refer-table-body text-left pl-3"
                        style={{ fontSize: "15px" }}
                        key={index}
                      >
                        <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                          {data.activities_type == "buybloqs"
                            ? "Buy Bloqs"
                            : data.activities_type == "swap"
                              ? "Swap"
                              : "Claim Rewards"}
                        </Col>
                        <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                          <a
                            style={{ textDecoration: "none" }}
                            href={urls + `${data.activities_hash}`}
                            target="blank"
                          >
                            {data.activities_hash
                              ? Slicer(data.activities_hash)
                              : "-"}
                          </a>
                        </Col>
                        <Col
                          xxl={2}
                          xl={2}
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          className="d-flex justify-content-start align-items-center"
                        >
                          {data.activities_type == "buybloqs" ? (
                            <Image
                              src={Dollarlogo}
                              fluid
                              height={20}
                              width={20}
                            />
                          ) : data.activities_type == "swap" ? (
                            <Image src={XDClogo} fluid height={20} width={20} />
                          ) : (
                            ""
                          )}
                          {data.activities_type == "Claim Rewards" ? (
                            "-"
                          ) : (
                            <span className="ml-2">
                              {data.activities_quantity}
                            </span>
                          )}
                        </Col>
                        <Col
                          xxl={2}
                          xl={2}
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <Image src={bloqs} fluid height={20} width={20} />
                          <span className="ml-2">
                            {data.activities_type == "Claim Rewards"
                              ? data.activities_price / 10
                              : formatter.format(data.activities_price)}
                          </span>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                          {FormatDate(data.activities_updatedat)},{" "}
                          {FormatTime(data.activities_updatedat)}
                        </Col>
                      </Row>
                    ))
                ) : (
                  <p className="text-center">No Activity History</p>
                )}
              </div>
            </div>
          </div>
          <div className="">
            <h4 className="font-weight-bold">My Assets</h4>
            <Row>
              {userNft.length > 0 && wallet.connected ? (
                userNft.map((data, i) => (
                  <Col
                    xxl={3}
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={6}
                    key={i}
                    className="mb-3"
                  >
                    <div
                      className="nft_card"
                      role="presentation"
                      onClick={() =>
                        nftClick(
                          data.nftcollections_id,
                          data.nftcollections_collection_id
                        )
                      }
                    >
                      <div
                        style={{
                          overflow: "hidden",
                          borderRadius: "1em 1em 0 0",
                        }}
                      >
                        <img
                          src={data.nftcollections_image}
                          alt="img"
                          style={{
                            height: window.innerWidth < 600 ? "8em" : "15em",
                            width: "100%",
                            objectFit: "cover",
                            borderRadius: "1em 1em 0 0",
                          }}
                          className="img-zoom-animation"
                        />
                      </div>
                      <h6 className="my-2 font-weight-bold">
                        {data.nftcollections_name.slice(0, 10)}
                      </h6>
                    </div>
                  </Col>
                ))
              ) : (
                <div>
                  {!wallet.connected ? (
                    <p className="text-center mt-3 text-dark">Connect Wallet</p>
                  ) : (
                    <p className="text-center mt-3 text-dark">No Assets</p>
                  )}
                </div>
              )}
            </Row>
          </div>
        </Stack>
      </div>
      <BuyBloqsModal
        buyBloqs={buyBloqs}
        setBuyBloqs={setBuyBloqs}
        getBloqsBalance={getBloqsBalance}
        getUserActivity={getUserActivity}
      />
      <SwapModal
        swap={swap}
        setSwap={setSwap}
        getXDCBalance={getXDCBalance}
        getBloqsBalance={getBloqsBalance}
        getUserActivity={getUserActivity}
      />
      <MessageModal
        msgShow={msgShow}
        setMsgShow={setMsgShow}
        message={message}
      />
      <RedeemRewards
        userReward={userReward}
        redeemModal={redeemModal}
        setRedeemModal={setRedeemModal}
        getUserRewards={getUserRewards}
        getUserActivity={getUserActivity}
        getBloqsBalance={getBloqsBalance}
      />
    </>
  );
}

export default Profile;
