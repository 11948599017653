import React, { useState } from "react";
import "./styles.css";
import { Box, Modal } from "@mui/material";
import { useSelector } from "react-redux";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { postMethod } from "../../helpers";
import Swal from "sweetalert2";

const horizontalLabels = {
  0: "0%",
  25: "25%",
  50: "50%",
  75: "75%",
  100: "100%",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth < 600 ? "90%" : "40%",
  bgcolor: "background.paper",
  border: "none !important",
  boxShadow: 2,
  p: 0,
  borderRadius: "1em",
};

const loader = require("../../assets/images/loading.gif").default;

function RedeemRewards(props) {
  let {
    redeemModal,
    setRedeemModal,
    getBloqsBalance,
    userReward,
    getUserActivity,
    getUserRewards
  } = props;
  const user = JSON.parse(localStorage.getItem("User"));
  const wallet = useSelector((state) => state.WalletConnect);
  const { address, web3, XDC_AirDrop } = wallet;
  const UserToken = localStorage.getItem("UserToken");
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [sliderval, setSliderval] = useState(0);
  const [error, setError] = useState("");
  //eslint-disable-next-line
  const [spendingPoints, setSpendingPoints] = useState(0);
  const [rew, setrew] = useState(0);

  var formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const handleslider = (e) => {
    setError("");
    setSliderval(e);
    var val = ((userReward / 10) * (e / 100)) * 10;
    setrew(Math.round(val)/10);
    setSpendingPoints(Math.round(val));
  };

  const redeemClick = async () => {
    if (sliderval > 0) {
      try {
        setLoading(true);
        let rewards = rew; 
        var time = Date.now();
        let url = "signatureXDCAirdrop";
        var amount = web3.utils.toWei(String(rewards), "ether");
        let params = {
          contractAddress: process.env.REACT_APP_XDCAIRDROP_CONTRACT,
          userAddress: address,
          amount: amount,
          isXDC: false,
          nonce: time,
        };
        let authtoken = UserToken;
        let responses = await postMethod({ url, params, authtoken });
        if (responses.signtuple) {
          try {
            //eslint-disable-next-line
            var Claim = await XDC_AirDrop.methods
              .claimAirDrops(responses.signtuple)
              .send({ from: address });
            let url = "Claimrewards";
            let params = {
              userid: user.user_id,
              amount: spendingPoints,
              wallet: address,
              hash: Claim.transactionHash,
            };
            let authtoken = UserToken;
            let response = await postMethod({ url, params, authtoken });
            // console.log("claim reward res", response);
            if (response.status == true) {
              var obj = JSON.parse(localStorage.getItem("User"));
              obj.rewards = user.rewards - spendingPoints;
              localStorage.setItem("User", JSON.stringify(obj));
              setLoading(false);
              setRedeemModal(false);
              Swal.fire({
                icon: "success",
                text: "Reward Claimed Successfully",
              });
              getBloqsBalance();
              getUserActivity();
              getUserRewards();
            } else {
              setRedeemModal(false);
            }
          } catch (e) {
            console.log("error", e);
            setRedeemModal(false);
          }
        } else {
          console.log("error", "Try Again");
          setRedeemModal(false);
        }
      } catch (e) {
        console.log(e);
        setRedeemModal(false);
      }
    } else {
      setError("Please Select Values to redeem reward");
    }
  };
  return (
    <div>
      <Modal
        open={redeemModal}
        onClose={() => setRedeemModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal_header">
            <h3 className="text-light fw-bold m-0">Redeem Rewards</h3>
            <span
              role="presentation"
              onClick={() => setRedeemModal(false)}
              style={{ cursor: "pointer", color: "white" }}
            >
              X
            </span>
          </div>

          <div className="modal_body mb-0">
            <div className="d-sm-flex justify-content-between align-items-center">
              <div className="right-swap-bal mx-1">
                <small className="center mb-2">
                  <img
                    src={require("../../assets/images/points.png").default}
                    height={20}
                    width={20}
                    style={{ objectFit: "contain" }}
                    alt="img"
                  />
                  Total Points
                </small>
                <div className="center">
                  <h6 className="text-center mb-0">
                    {formatter.format(userReward)}
                  </h6>
                </div>
              </div>
              <div className="right-swap-bal mx-1">
                <small className="center mb-2">
                  <img
                    src={require("../../assets/images/points.png").default}
                    height={20}
                    width={20}
                    style={{ objectFit: "contain" }}
                    alt="img"
                  />
                  Spending Points
                </small>
                <div className="center">
                  <h6 className="text-center mb-0">
                    {userReward == 0
                      ? formatter.format(userReward)
                      : formatter.format(spendingPoints)}
                  </h6>
                </div>
              </div>
              <div className="right-swap-bal mx-1">
                <small className="center mb-2">
                  <img
                    src={require("../../assets/images/reward.png").default}
                    height={20}
                    width={20}
                    alt="img"
                  />
                  Estimate
                </small>
                <div className="center">
                  <h6 className="text-center mb-0">{formatter.format(rew)}</h6>
                  <img
                    src={require("../../assets/images/bloqs.png").default}
                    style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                    alt="img"
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                padding: "10px 0",
                marginBottom: "15px",
              }}
            >
              <Slider
                color="blue"
                step={25}
                min={0}
                max={100}
                value={sliderval}
                onChange={handleslider}
                labels={horizontalLabels}
              />
            </div>
            {error.length > 0 && (
              <p className="text-danger text-center">{error}</p>
            )}
            <div className="center mt-2">
              <button className="primary-button" onClick={redeemClick}>
                Redeem
                {loading ? (
                  <img
                    src={loader}
                    height={20}
                    width={20}
                    className="ml-2"
                    alt="img"
                  />
                ) : null}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default RedeemRewards;
