import React from "react";
import { Modal } from "react-bootstrap";
import "./styles.css";

function FeedbackModal({ show, handleClose, setShow }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="my-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-body schedulebody">
        <div className="logingrayinn mainloginin">
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => setShow(false)}
          ></button>
          <h2>Feedback</h2>
          <i></i>
          <form className="scheduleinn">
            <div className="form-group">
              <input type="text" placeholder="Name" className="form-control" />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Email Address"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <textarea
                placeholder="Details"
                rows="3"
                className="form-control"
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default FeedbackModal;
