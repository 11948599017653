import React, { useState } from "react";
import { Container, Nav, Image, Navbar } from "react-bootstrap";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import ConenctWallet from "../connectwallet";
import { useDispatch, useSelector } from "react-redux";
import { connectFailed } from "../../redux/WalletAction";
import { BsPersonCircle } from "react-icons/bs";

const logos = require("../../assets/images/metablog_logo.png").default;

function Header() {
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [openWallet, setOpenWallet] = useState(false);
  const wallet = useSelector((state) => state.WalletConnect);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const UserToken = localStorage.getItem("UserToken");
  const UserName = localStorage.getItem("UserName");
  const user = JSON.parse(localStorage.getItem("User"));

  const connect = () => {
    setOpenWallet(true);
    setNavbarExpanded(false);
  };
  const errorDiv = () => {
    return <p>Wallet Disconnected!</p>;
  };
  const disconnect = () => {
    const { web3Modal } = wallet;
    setNavbarExpanded(false);
    web3Modal.clearCachedProvider();
    dispatch(connectFailed(errorDiv()));
  };
  return (
    <>
      <Navbar
        fixed="top"
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="light"
        expanded={navbarExpanded}
        className="header_container"
      >
        <Container fluid className="px-5">
          <NavLink to={"/"} className="">
            <Image src={logos} fluid alt="metabloq_logo" width={165} />
          </NavLink>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() =>
              setNavbarExpanded(navbarExpanded ? false : "expanded")
            }
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <NavLink
                to="/#partner"
                smooth={true}
                spy="true"
                duration={2000}
                className="nav-link"
                onClick={() => setNavbarExpanded(false)}
              >
                Partner
              </NavLink>
              <NavLink
                to="/#team"
                smooth={true}
                spy="true"
                duration={2000}
                className="nav-link"
                onClick={() => setNavbarExpanded(false)}
              >
                Team
              </NavLink>
              <NavLink
                to="/whitepaper"
                smooth={true}
                spy="true"
                duration={1000}
                className="nav-link"
                onClick={() => setNavbarExpanded(false)}
              >
                White Paper
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ConenctWallet openWallet={openWallet} setOpenWallet={setOpenWallet} />
    </>
  );
}

export default Header;
