import React from "react";
import { Route, Routes } from "react-router-dom";
import BetaVersion from "../screens/BETA/BetaVersion";
import Sidebar from "../components/sidebar/Sidebar";
import Home from "../components/sidebar/pages/Home";
import Game from "../components/sidebar/pages/Game";
import Profile from "../components/sidebar/pages/Profile";
import Map from "../components/sidebar/pages/Map";
import Login from "../screens/login/Login";
import ForgotPassword from "../screens/login/ForgotPassword";
import Main from "../screens/Main";
import ResetPassword from "../screens/login/ResetPassword";
import Signup from "../screens/login/Signup";
import VerifyEmail from "../screens/login/VerifyEmail";
import EditProfile from "../components/sidebar/pages/EditProfile";
import WhitePaper from "../screens/whitepaper";

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Main />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgotpswd" element={<ForgotPassword />} />
      <Route path="/resetpswd" element={<ResetPassword />} />
      <Route path="/verifyemail" element={<VerifyEmail />} />
      <Route path="/betaVersion" element={<BetaVersion />} />
      <Route path="/whitepaper" element={<WhitePaper/>}/>
      <Route path="/sidebar" element={<Sidebar />}>
        <Route index element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="game" element={<Game />} />
        <Route path="profile" element={<Profile />} />
        <Route path="map" element={<Map />} />
        <Route path="profile/editprofile" element={<EditProfile/>}/>
      </Route>
    </Routes>
  );
};

export const CommonRoutes = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/betaVersion" element={<BetaVersion />} />
        <Route path="/forgotpswd" element={<ForgotPassword />} />
        <Route path="/resetpswd" element={<ResetPassword />} />
        <Route path="/verifyemail" element={<VerifyEmail />} />
        <Route path="/whitepaper" element={<WhitePaper/>}/>
      </Routes>
    </>
  );
};
