import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { postMethod } from "../../helpers";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("state what=", state);
  const submitClick = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Email is Required!");
    } else {
      let mail = email.trim();
      let url = "forgotPassword";
      let params = {
        email: mail,
      };
      let authtoken = "";
      let response = await postMethod({ url, params, authtoken });
      console.log("send otp mail res", response);
      if (response.status == true) {
        navigate("/resetpswd", {
          state: { mail: mail, existing: state.existing ? state.existing : "" },
        });
      } else {
        setError(response.message);
      }
    }
  };
  return (
    <>
      <div className="logingraytop" style={{ height: "100vh" }}>
        <div className="inner-login loginpage h-100">
          <div className="logingray">
            <div className="container">
              <div className="row">
                <div className="col-md-6 mx-auto">
                  <div className="logingrayinn mainloginin">
                    <div className="logingrayinnblur">
                      {
                        state.existing && <h4 className="text-light text-center font-weight-bold">Welcome Back, Please Reset your Password to continue</h4>
                      }
                      <p className="text-center text-light">We will send OTP to your registered <br/> email to reset your password</p>
                    </div>
                    <form className="loginformmain">
                      <div className="col-md-12 p-0">
                        <div className="form-group w-100">
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            placeholder="Email Address"
                          />
                        </div>
                        {error !== "" ? (
                          <div className="text-center">
                            <span className="text-danger">{error}</span>
                          </div>
                        ) : null}
                      </div>
                      <button
                        className="primary-square-button w-100 mt-2"
                        onClick={submitClick}
                      >
                        Get OTP
                        <span id="waitLoginID" className="loader-4">
                          {" "}
                        </span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
