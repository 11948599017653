import React, { useState, useEffect, useCallback } from "react";
import { Image } from "react-bootstrap";
// import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { TileMap } from "react-tile-map";
import { useSelector } from "react-redux";
// import { Popup } from "decentraland-ui";
import "./Styles.css";
const placeholder = require("../../assets/images/profile_placeholder.png").default;

export const COLOR_BY_TYPE = Object.freeze({
  0: "#ff9990", //
  1: "#ff4053", //parcels on sale
  2: "#ff9990", //parcels on auction
  3: "#fcba03", //estates
  4: "#ffbd33", //
  5: "#afd6e8", //
  6: "#563db8", //
  7: "#fcfcfc", // road
  8: "#a9d889", // public
  9: "#bd3ce8", // already owned parcels
  10: "#3D3A46", //
  11: "#0d6efd", //available   ready to buy --->(buy only type 11)
  12: "#4a4949", // not available  under process
  13: "#cccccc", //
  14: "#0d0b0e", //
  15: "#00eeff",

  // 0: "#ff9990", // my parcels
  // 1: "#ff4053", // my parcels on sale
  // 2: "#ff9990", // my estates
  // 3: "#ff4053", // my estates on sale
  // 4: "#ffbd33", // parcels/estates where I have permissions
  // 5: "#afd6e8", // districts
  // 6: "#563db8", // contributions
  // 7: "#fcfcfc", // roads
  // 8: "#a9d889", // plazas #
  // 9: "#ebebea", // owned parcel/estate
  // 10: "#3D3A46", // parcels on sale (we show them as owned parcels)
  // 11: "#09080A", //avaiable    // unowned pacel/estate
  // 12: "#000000", // background
  // 13: "#000000", // loading odd
  // 14: "#0d0b0e", // loading even
  // 15: "#00eeff",

  //old ones
  // 0: "#ff9990", // my parcels
  // 1: "#ff4053", // my parcels on sale
  // 2: "#ff9990", // my estates
  // 3: "#ff4053", // my estates on sale
  // 4: "#ffbd33", // parcels/estates where I have permissions
  // 5: "#5054D4", // districts
  // 6: "#563db8", // contributions
  // 7: "#716C7A", // roads
  // 8: "#70AC76", // plazas
  // 9: "#3D3A46", // owned parcel/estate
  // 10: "#3D3A46", // parcels on sale (we show them as owned parcels)
  // 11: "#09080A", // unowned pacel/estate
  // 12: "#18141a", // background
  // 13: "#110e13", // loading odd
  // 14: "#0d0b0e", // loading even
});
function Atlas({
  setSelectedAxis,
  parcels,
  filterType,
  onSale,
  selectedParcels,
  onSelectGrid,
  filterTypeValue,
}) {
  const reduxItems = useSelector((state) => state.WalletConnect);
  const { address } = reduxItems;
  const [showPopup, setShowPopup] = useState(false);
  const [hoveredTile, setHoveredTile] = useState({
    type: 9,
    x: 16,
    y: -11,
    owner: "0xff6bbfbd72551f02be3109ad6eec9ed469bf7667",
  });
  // const [mouseX, setMouseX] = useState(-1);
  // const [mouseY, setMouseY] = useState(-1);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const getCoords = (x, y) => `${x},${y}`;

  const handleHidePopup = useCallback(() => {
    setShowPopup(false);
    // setMouseX(-1);
    // setMouseY(-1);
  }, []);
  const handleHover = useCallback(
    (x, y) => {
      setX(x);
      setY(y);
      const id = getCoords(x, y);
      const tile = parcels[id];
      if (tile && !showPopup) {
        setShowPopup(true);
        setHoveredTile(tile);
        // setMouseX(-1);
        // setMouseY(-1);
      } else if (tile && tile !== hoveredTile) {
        setHoveredTile(tile);
        // setMouseX(-1);
        // setMouseY(-1);
      } else if (!tile && showPopup) {
        setShowPopup(false);
      }
    },
    [hoveredTile, showPopup, parcels]
  );


  const isowner = useCallback((x, y) => {
    const id = getCoords(x, y);
    const tile = parcels[id];
    if (address) {
      if (tile?.owner == address && tile?.type == 9) {
        // return { color: "#545559"}
      }
    }

  }, []);

  const getType = useCallback((x, y) => {
    const id = getCoords(x, y);
    const tile = parcels[id];
    if (tile?.type == 11) {
      return true;
    } else {
      return false;
    }
  }, []);

  const [selected, setSelected] = useState([]);

  const isSelected = (x, y) => {
    return selected.some((coord) => coord.x === x && coord.y === y);
  };
  const selectedStrokeLayer = (x, y) => {
    return isSelected(x, y) ? { color: "#00ff00", scale: 1.4 } : null;
  };
  const selectedStrokeLayerRed = (x, y) => {
    return isSelected(x, y) ? { color: "red", scale: 1.4 } : null;
  };
  const filteredLayer = (x, y) => {
    const key = getCoords(x, y);
    const tile = parcels[key];
    if (tile?.type == parseInt(filterType)) {
      return {
        color: "red",
        scale: 1.4,
      };
    } else {
      return null;
    }
  };
  const filteredLayervalue = (x, y) => {
    const key = getCoords(x, y);
    const tile = parcels[key];
    if (tile?.bloqs_price == parseInt(filterTypeValue)) {
      return {
        color: "red",
        scale: 1.4,
      };
    } else {
      return null;
    }
  };

  const selectedFillLayer = (x, y) => {
    return isSelected(x, y) ? { color: "#00dd00", scale: 1.2 } : null;
  };
  let atlas = parcels;
  const atlasLayer = (x, y) => {

    const id = x + "," + y;
    if (atlas !== null && id in atlas) {
      const tile = atlas[id];
      const color = COLOR_BY_TYPE[tile.type];

      const top = !!tile.top;
      const left = !!tile.left;
      const topLeft = !!tile.topLeft;
      return {
        color,
        top,
        left,
        topLeft,
        image: (<img src={placeholder} alt="Tile" />)
      };
    } else {
      return {
        color: COLOR_BY_TYPE[13],
        top: true,
        left: true,
        topLeft: true,
        image: (<img src={placeholder} alt="Tile" />)
      };
    }
  };

  const forSaleLayer = useCallback((x, y) => {
    const key = getCoords(x, y);
    const tile = parcels[key];
    if (getType(x, y)) {
      return {
        color: "#9c26f0",
        left: !!tile.left,
        top: !!tile.top,
        topLeft: !!tile.topLeft,
      };
    }
    return null;
  }, []);

  useEffect(() => {
    if (selectedParcels) {
      setSelected(selectedParcels);
      if (!selectedParcels) {
      }
    }
  }, []);
  var layers = [atlasLayer, selectedStrokeLayer, selectedFillLayer, isowner];
  if (selectedParcels) {
    layers = [atlasLayer, selectedStrokeLayerRed, isowner];
  }
  if (onSale) {
    layers = [atlasLayer, forSaleLayer, selectedStrokeLayer, selectedFillLayer, isowner];
  }
  if (filterType != null) {
    layers = [
      atlasLayer,
      filteredLayer,
      selectedStrokeLayer,
      selectedFillLayer,
      isowner
    ];
  }

  if (filterTypeValue != null) {
    layers = [
      atlasLayer,
      filteredLayervalue,
      selectedStrokeLayer,
      selectedFillLayer,
      isowner
    ];
  }
  return (
    <div
      style={{ height: 600, backgroundColor: "#000" }}
      onMouseLeave={handleHidePopup}
    >
      <TileMap
        className="map"
        height={600}
        width={(window.innerWidth / 100) * 81}
        isDraggable={false}
        layers={layers}
        onHover={handleHover}
        zoom={0.8}
        image={placeholder}
        x={1}
        y={-25}
      />
      {showPopup ? (
        <div
          className="tip"
          style={{
            top: y > 0 ? y + 100 : y + 200,
            left: x > 0 ? x + 100 : x + 300,
          }}>
          {hoveredTile.type == 8
            ? "Place: " +
            "public place" +
            "\n" +
            "x:" +
            hoveredTile.x +
            "\n" +
            "y:" +
            hoveredTile.y
            : hoveredTile.type == 7
              ? "Place: Road" +
              "" +
              "\n" +
              "x:" +
              hoveredTile.x +
              "\n" +
              "y:" +
              hoveredTile.y
              : hoveredTile.type == 5
                ? "Place: Sea" +
                "" +
                "\n" +
                "x:" +
                hoveredTile.x +
                "\n" +
                "y:" +
                hoveredTile.y
                : "Owner:" + hoveredTile.owner &&
                  hoveredTile.owner ? hoveredTile.owner +
                  "\n" +
                  "x:" +
                  hoveredTile.x +
                  "\n" +
                  "y:" +
                hoveredTile.y :
                  "\n" +
                  "x:" +
                  hoveredTile.x +
                  "\n" +
                  "y:" +
                  hoveredTile.y
          }
          {hoveredTile?.name && "\n" + "Name:" + hoveredTile?.name}
          {hoveredTile?.isEstate && "\n" + "Estate"}
          {hoveredTile?.image && (
            <Image
              src={hoveredTile?.image}
              fluid
              height={200}
              width={200}
              style={{ borderRadius: 5 }}
              alt={""}
            />
          )}

        </div>
      ) : null}
    </div>
  );
}

export default Atlas;
