import React from "react";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate()
  return (
    <div className="gapside">
      <footer>
        <div className="container">
          <div className="topheader">
            <div className="row">
              <div className="col-lg-8">
                <div className="foot-logo">
                  {/* eslint-disable-next-line */}
                  <a  onClick={()=> navigate("/")}>
                    {" "}
                    <img
                      src="https://static.metabloqs.com/web/assets1/images/brand-logo/logo.png"
                      alt="img"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-4 footer-info">
                <ul className="footer-social">
                  <li>
                    <a
                      href="https://www.linkedin.com/authwall?trk=ripf&trkInfo=AQGFoY3sxqAgugAAAX8__CrI9de4uJsOIKY0Titd_A21DSsQ5DAIKaEH2mnYAvJrEIZAixYGdqrt6rTQMFeGsAtLXtk-6gTM1qql5z9GjGUgSR9lr-21cxBiR6bQ5ggic-XmKvo=&originalReferer=https://yeorder.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F71723804%2Fadmin%2F"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://static.metabloqs.com/web/assets1/images/linkedin.png"
                        alt="img"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 footer-info">
                <h4>About</h4>
                <ul className="footer-links">
                  <li>
                    <a href="/#partner" target={"_self"}>Partner With Us</a>
                  </li>
                  <li>
                    {" "}
                    <a href="/#ecosystem" target={"_self"} >Ecosystem</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="/#team" target={"_self"} >Team</a>{" "}
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 footer-info">
                <h4>Contact</h4>
                <ul className="footer-links">
                  <li>
                    {" "}
                    <a href="mailto:info@metabloqs.com">
                      info@metabloqs.com
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="mailto:press@metabloqs.com">
                      press@metabloqs.com
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="mailto:career@metabloqs.com">
                      career@metabloqs.com
                    </a>{" "}
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 footer-info lastinfo">
                <ul className="footer-links">
                  <li>
                    {" "}
                    <a
                      href="https://s3.eu-central-1.amazonaws.com/static.metabloqs.com/public/Metabloqs_Whitepaper.pdf"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Metabloqs Whitepaper
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a
                      href=" https://xinfin.org/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Xinfin Blockchain Network
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a
                      href="https://s3.eu-central-1.amazonaws.com/static.metabloqs.com/public/Airdrop_Terms_and_Conditions.pdf"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Terms & Conditions
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a
                      href="https://s3.eu-central-1.amazonaws.com/static.metabloqs.com/public/Privacy_Policy.pdf"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a
                      href="https://s3.eu-central-1.amazonaws.com/static.metabloqs.com/public/Cookie_Policy.pdf"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {" "}
                      Cookie Policy
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <p className="footer-bottom">
                &copy; 2022 Metabloqs. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
