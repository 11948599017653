import React, { useEffect, useState } from "react";
import "./styles.css";
import { Stack } from "react-bootstrap";
import { Box, Modal } from "@mui/material";
import { useSelector } from "react-redux";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { postMethod } from "../../../helpers";
import Swal from "sweetalert2";

const horizontalLabels = {
  0: "0%",
  25: "25%",
  50: "50%",
  75: "75%",
  100: "100%",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth < 600 ? "90%" : "40%",
  bgcolor: "background.paper",
  border: "none !important",
  boxShadow: 2,
  p: 0,
  borderRadius: "1em",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth < 600 ? "90%" : "40%",
  bgcolor: "background.paper",
  border: "none !important",
  boxShadow: 2,
  p: 0,
  borderRadius: "1em",
};

const metetoken = require("../../../assets/images/logo_block.png").default;
const xdctokenblc = require("../../../assets/images/xdc-icon.png").default;
const loader = require("../../../assets/images/loading.gif").default;

function SwapModal(props) {
  let { swap, setSwap, getBloqsBalance, getXDCBalance, getUserActivity} = props;
  const wallet = useSelector((state) => state.WalletConnect);
  const { address, connected, XDC_AirDrop, web3, Token } = wallet;
  const [XDCbalance, setXDCbalance] = useState(0);
  const [Tokenbalance, setTokenbalance] = useState(0);
  const [sliderval, setSliderval] = useState();
  const [xdcvalue, setxdcvalue] = useState(0);
  const [bloqsvalue, setbloqsvalue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [bloqrate,setbloqrate] = useState(0);
  const UserToken = localStorage.getItem("UserToken");

  //eslint-disable-next-line
  const [confirmmodal, setConfirmmodal] = useState(false);
  
  const getacc = async () => {
    try{
      var data = await web3.eth.getBalance(address);
      var tokenblc = await Token.methods.balanceOf(address).call();
      data = web3.utils.fromWei(data, "ether");
      tokenblc = web3.utils.fromWei(tokenblc, "ether");
      setTokenbalance(tokenblc);
      setXDCbalance(data);
    }catch(err){
      console.log("Error in getBalance",err)
    }
    try{
      const bloqsperrate = await XDC_AirDrop.methods.tokenRate().call();
      setbloqrate(bloqsperrate)
    }catch(err){
      console.log("Error in getBolqsrate",err)
    }

  };

  const handleslider = (e) => {
    setSliderval(e);
    if (XDCbalance) {
      var val = XDCbalance * (e / 100);
      setxdcvalue(val.toFixed(2));
      setbloqsvalue((val * bloqrate).toFixed(2));
    }
  };
  const handlesetvalue = (e) => {
    setxdcvalue(e);
    setbloqsvalue(e * bloqrate);
  };

  const swapfun = async () => {
    if (!connected) {
      console.log("connect wallet");
      return;
    }
    if (!xdcvalue) {
      console.log("xdc balance");
      return;
    }
    setLoading(true);
    var amt = web3.utils.toWei(xdcvalue, "ether");
    try {
      var swap = await XDC_AirDrop.methods
        .SwapXDCtoMetabloqs()
        .send({ from: address, value: amt });
      if (swap.status) {
        try {
          let url = "createActivitiesbloqs";
          let params = {
            wallet: address,
            hash: swap?.transactionHash,
            from: address, //data.nftcollections_wallet,
            to: "", //address,
            type: "swap",
            price: xdcvalue * bloqrate,
            quantity: xdcvalue,
            tranfer: "",
            amounttype: "Bloqs",
          };
          let authtoken = UserToken;
          let response = await postMethod({
            url,
            params,
            authtoken,
          });
          getacc();
          Swal.fire({icon: "success",text:`Your ${xdcvalue * bloqrate} bloqs Swapped Successful`});
          getBloqsBalance();
          getXDCBalance();
          getUserActivity();
          setSwap(false);
        } catch (e) {
          console.log("Error",e);
          setSwap(false);
        }
      }else{
        setSwap(false);
      }
      setLoading(false);
    } catch (e) {
      console.log("Error", e);
      setSwap(false);
    }
  };

  useEffect(() => {
    if (address) {
      getacc();
    }
  }, [connected]);

  var formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return (
    <div>
      <Modal
        open={swap}
        onClose={() => setSwap(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack gap={3}>
            <div className="modal_header">
              <h3 className="text-light fw-bold m-0">Swap XDC to Bloqs</h3>
              <span
                role="presentation"
                onClick={() => setSwap(false)}
                style={{ cursor: "pointer", color: "white" }}
              >
                X
              </span>
            </div>
            <div className="d-sm-flex justify-content-between align-items-center px-3">
              <div className="left-swap-bal">
                <small className="center">
                  <img
                    src={xdctokenblc}
                    height={25}
                    width={25}
                    alt="img"
                    className="mr-2"
                  />
                  XDC
                </small>
                <h6 className="m-0">{formatter.format(XDCbalance)}</h6>
              </div>
              <div className="left-swap-bal">
                <small className="center">
                  <img
                    src={metetoken}
                    height={25}
                    width={25}
                    alt="img"
                    className="mr-2"
                  />
                  Bloqs
                </small>
                <h6 className="m-0">
                  {formatter.format(Math.round(Tokenbalance))}
                </h6>
              </div>
            </div>

            <div className="modal_body">
              <div className="between">
                <div className="buybloq-input-box">
                  <input
                    placeholder="Enter XDC value"
                    className="buybloqs-input"
                    value={xdcvalue}
                    onChange={(e) => handlesetvalue(e.target.value)}
                  />
                  <div className="between">
                    <img
                      src={xdctokenblc}
                      style={{ width: "20px", height: "20px" }}
                      alt="img"
                    />
                    <p className="text-center ml-2 mb-0">XDC</p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  padding: "10px 0",
                  marginBottom: "10px",
                }}
              >
                <Slider
                  color="blue"
                  step={5}
                  min={0}
                  max={100}
                  value={sliderval}
                  onChange={handleslider}
                  labels={horizontalLabels}
                />
              </div>
              <div className="between">
                <div className="buybloq-input-box">
                  <input
                    placeholder="250"
                    value={bloqsvalue}
                    className="buybloqs-input"
                    readOnly
                  />
                  <div className="between">
                    <img
                      src={metetoken}
                      style={{ width: "20px", height: "20px" }}
                      alt="img"
                    />
                    <p className="text-center ml-2 mb-0">Bloqs</p>
                  </div>
                </div>
              </div>
              <div className="center mt-2">
                <button
                  className="primary-button"
                  onClick={() => swapfun(false)}
                >
                  Swap
                  {loading ? (
                    <img
                      src={loader}
                      height={20}
                      width={20}
                      className="ml-2"
                      alt="img"
                    />
                  ) : null}
                </button>
              </div>
            </div>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={confirmmodal}
        // onClose={setConfirmmodal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <div style={{ borderRadius: "1em" }}>
            <div
              style={{
                background: "linear-gradient(90deg, #6DC6FE 0%, #0295FA 100%)",
                borderRadius: "1em 1em 0 0",
              }}
              className="d-flex justify-content-between align-items-center py-3 px-3"
            >
              <h3 className="font-weight-bold m-0 text-light">Swap</h3>
              <small
                role="presentation"
                onClick={() => setConfirmmodal(false)}
                style={{ cursor: "pointer", color: "white" }}
              >
                X
              </small>
            </div>
            <div className="py-2 text-center h-100 mx-5">
              <h4>Would you like to proceed</h4>
              <br />
            </div>
            <div
              className="d-flex px-5 py-3"
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <button
                onClick={() => setConfirmmodal(false)}
                style={{ width: "40%" }}
                className="primary-square-button"
              >
                <span> Cancel </span>
              </button>

              <button
                onClick={() => swapfun(false)}
                style={{ width: "40%" }}
                className="primary-square-button"
              >
                <span> Proceed </span>
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default SwapModal;
