import React from "react";
import { Col, Row,Image } from "react-bootstrap";
// import { Fade } from "react-reveal";
import "./PressRelease.css";
// import {
//   FaFacebookF,
//   FaTwitter,
//   FaFacebookMessenger,
//   FaYoutube,
//   FaInstagram,
//   FaLinkedin,
//   FaSnapchatGhost,
// } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";
// import Bounce from "react-reveal/Bounce";

// const quotes = require("../../assets/images/quotes.png").default;
// const card_img = require("../../assets/images/card_img.png").default;
// const vilot_effect = require("../../assets/images/vilot_effect.png").default;
// const blue_effect = require("../../assets/images/blue_effect.png").default;
// const navigation = require("../../assets/images/navigation.png").default;

const first = [
  {
    id: 1,
    name: "Yahoo Finance",
    image:
      "https://s.yimg.com/ny/api/res/1.2/ucHfvuI2N_r9zvIpzTLBJg--/YXBwaWQ9aGlnaGxhbmRlcjt3PTk2MDtoPTYyOTtjZj13ZWJw/https://ml.globenewswire.com/Resource/Download/c566f027-0a7d-40e4-a4d3-d3a2c9e05d45",
    description:
      "Metabloqs shows up as a metaverse that aims to democratize the web3 space with the main focus on fostering trust and transparency. Inclusive to people from all lifestyles.",
    link: "https://finance.yahoo.com/news/metabloqs-announces-launch-metaverse-project-044900975.html",
    logo:require("../../assets/images/yahoo_finance.png").default
  },
  {
    id: 2,
    name: "Coinpedia",
    image:
      "https://image.coinpedia.org/wp-content/uploads/2022/03/07175440/Metabloqs.webp",
    description:
      "With the motive to extend the vibrance of reality onto the virtual world, Metabloqs offers a real trusted digital economy with numerous purposes. Metabloqs is the revolutionary progeny from eTrain, the course platform. ",
    link: "https://coinpedia.org/sponsored/introduction-to-metabloqs-a-real-world-metaverse/",
    logo:require("../../assets/images/coinpedia.png").default
  },
  {
    id: 3,
    name: "Globe Newswire",
    image: require("../../assets/images/hero-banner.png").default,
    description:
      "The prime mission of Metabloqs is to build a metaverse that offers a compelling economy for networking, socializing, learning, and many more.",
    link: "https://www.globenewswire.com/news-release/2022/09/13/2515121/0/en/Metabloqs-the-first-Swiss-based-metaverse-just-opened-up-to-its-first-5000-users.html",
    logo:require("../../assets/images/global.svg").default
  },
  {
    id: 4,
    name: "LA Weekly",
    image:
      "https://www.laweekly.com/wp-content/uploads/2022/09/Screen-Shot-2022-09-12-at-6.34.55-PM.jpg",
    description:
      "Along with real-world cities as its foundation, Metabloqs expands as a  metaverse with a trustworthy and fully compliant ecosystem to diverse sectors - retail, finance, education, entertainment, and so on. ",
    link: "https://www.laweekly.com/metabloqs-the-first-swiss-based-metaverse-has-opened-up-to-an-initial-5000-beta-users-today/",
    logo:require("../../assets/images/laweekly.png").default
  },
  {
    id: 5,
    name: "Finews.ch",
    image:
      "https://www.finews.ch/images/cache/9664f266743294ffa7df82b0390f8670_w500_h300_cp.jpg",
    description:
      "Metabloqs recreates the grandeur of Paris within its unique Swiss-based metaverse that extends enriched virtual experience",
    link: "https://www.finews.ch/news/finanzplatz/53340-erstes-schweizer-metaverse-oeffnet-seine-tore",
    logo:require("../../assets/images/finews.svg").default
  },
  {
    id: 6,
    name: "TheNewsCrypto",
    image: require("../../assets/images/web1.jpg").default,
    description:
      "Metabloqs positions to become the trusted and sustainable metaverse of choice for business, finance, education, and entertainment",
    link: "https://thenewscrypto.com/metabloqs-first-real-world-inspired-swiss-metaverse/",
    logo:require("../../assets/images/newscrypto.svg").default
  },
  {
    id: 7,
    name: "Cointelegraph",
    image:
      "https://images.cointelegraph.com/images/717_aHR0cHM6Ly9zMy5jb2ludGVsZWdyYXBoLmNvbS9zdG9yYWdlL3VwbG9hZHMvdmlldy80ZmQ2NjhlMjRkMTkzZDhhMWM1MTE1MmFkMmM0Y2ZhNy5qcGc=.jpg",
    description:
      "The synonyms of Metabloqs metaverse to the real world has left the Metacitizens awe-struck. Beta users strolled through the streets of the 7th arrondissement exploring the Eiffel Tower and the Champ de Mars",
    link: "https://fr.cointelegraph.com/press-releases/this-metaverse-beta-takes-you-to-the-heart-of-paris",
    logo:require("../../assets/images/cointelegraph.png").default
  },
  {
    id: 8,
    name: "TechBullion",
    image:
      "https://techbullion.com/wp-content/uploads/2022/09/Metabloqs_Onboards_First_5k_Users_on_Beta_Version_of_MetaParis_3.jpg",
    description:
      "Metabloqs, metaverse built over the XDC platform is gearing up for expanding its compatibility with a range of devices. The successful beta testing with 5K users has escalated hype among the community.",
    link: "https://techbullion.com/xdc-based-first-swiss-metaverse-metabloqs-launching-beta-testing-for-first-5k-citizens/",
    logo:require("../../assets/images/techbullion.png").default
  },
  {
    id: 9,
    name: "TheNewsCrypto",
    image: require("../../assets/images/web2.jpg").default,
    description:
      "The early adopters of the beta version are optimistic about Metabloqs metaverse. The Metabloqs team will soon open the gates of this metaverse to 300K+ users.",
    link: "https://thenewscrypto.com/metabloqs-first-xdc-based-metaverse-launched-its-successful-beta-testing/",
    logo:require("../../assets/images/newscrypto.svg").default
  },
  {
    id: 10,
    name: "City AM ",
    image:
      "https://www.cityam.com/wp-content/uploads/2022/10/Crypto-AM-The-DAO-Driver-with-Katia-Shabanova.jpg?resize=742,463",
    description:
      "Blockchain and the crypto market are setting an example to reduce gender parity that is in prevalence. And the women pioneers in this field are taking efforts to bring up other women in the world too. Metabloqs’ CEO Megha Shrestha, is one of the prominent “Women who build the industry”.",
    link: "https://www.cityam.com/how-women-are-making-their-mark-in-the-male-dominated-crypto-world/",
    logo:require("../../assets/images/cityam.svg").default
  },
  {
    id: 11,
    name: "CFTE Podcast",
    image: require("../../assets/images/stadium.png").default,
    description:
      "Metabloqs CEO Megha Shrestha and Chairman Marc-André Schauwecker join in for exclusive interviews & podcasts emphasizing the salience of the wholly reality-inspired metaverse.",
    link: "https://courses.cfte.education/podcasts/metabloqs-a-new-reality/",
    logo:require("../../assets/images/cfte.png").default
  },
  {
    id: 12,
    name: "Chainleak Podcast",
    image: "https://miro.medium.com/max/720/1*sjTE2vNgKe7ekdfH6LfyMQ.jpeg",
    description:
      "Joshuwa Roomsburg of ChainLeak had an intriguing conversation about Metabloqs, the first metaverse built over XDC, with Megha Shrestha, CEO of Metabloqs. ",
    link: "https://chainleak.medium.com/interview-with-megha-shrestha-founder-ceo-of-metabloqs-e83bf44dd94e",
    logo:require("../../assets/images/chainleak.jpeg").default
  },
];

// const second = [
//   {
//     id: 1,
//     name: "Cointelegraph",
//     image: require("../../assets/images/futbg.jpg").default,
//     description:
//       "The synonyms of Metabloqs metaverse to the real world has left the Metacitizens awe-struck. Beta users strolled through the streets of the 7th arrondissement exploring the Eiffel Tower and the Champ de Mars",
//     link: "https://fr.cointelegraph.com/press-releases/this-metaverse-beta-takes-you-to-the-heart-of-paris",
//   },
//   {
//     id: 2,
//     name: "TechBullion",
//     image: require("../../assets/images/live-in-art.png").default,
//     description:
//       "Metabloqs, metaverse built over the XDC platform is gearing up for expanding its compatibility with a range of devices. The successful beta testing with 5K users has escalated hype among the community.",
//     link: "https://techbullion.com/xdc-based-first-swiss-metaverse-metabloqs-launching-beta-testing-for-first-5k-citizens/",
//   },
//   {
//     id: 3,
//     name: "TheNewsCrypto",
//     image: require("../../assets/images/futbg.jpg").default,
//     description:
//       "The early adopters of the beta version are optimistic about Metabloqs metaverse. The Metabloqs team will soon open the gates of this metaverse to 300K+ users.",
//     link: "https://thenewscrypto.com/metabloqs-first-xdc-based-metaverse-launched-its-successful-beta-testing/",
//   },
// ];

// const third = [
//   {
//     id: 1,
//     name: "City AM ",
//     image: require("../../assets/images/futbg.jpg").default,
//     description:
//       "Blockchain and the crypto market are setting an example to reduce gender parity that is in prevalence. And the women pioneers in this field are taking efforts to bring up other women in the world too. Metabloqs’ CEO Megha Shrestha, is one of the prominent “Women who build the industry”.",
//     link: "https://www.cityam.com/how-women-are-making-their-mark-in-the-male-dominated-crypto-world/",
//   },
// ];

// const fourth = [
//   {
//     id: 1,
//     name: "CFTE Podcast",
//     image: require("../../assets/images/futbg.jpg").default,
//     description:
//       "Metabloqs CEO Megha Shrestha and Chairman Marc-André Schauwecker join in for exclusive interviews & podcasts emphasizing the salience of the wholly reality-inspired metaverse.",
//     link: "https://courses.cfte.education/podcasts/metabloqs-a-new-reality/",
//   },
//   {
//     id: 2,
//     name: "Chainleak Podcast",
//     image: require("../../assets/images/live-in-art.png").default,
//     description:
//       "Joshuwa Roomsburg of ChainLeak had an intriguing conversation about Metabloqs, the first metaverse built over XDC, with Megha Shrestha, CEO of Metabloqs. ",
//     link: "https://chainleak.medium.com/interview-with-megha-shrestha-founder-ceo-of-metabloqs-e83bf44dd94e",
//   },
// ];

function PressRelease() {
  const pressCardClick = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div className="prerelease-container">
      <div className="metafoolballtop">
        <h2>Press release</h2>
        <p className="press-para">
          See what the world is saying about us and expplore the new <br />
          possiblities of metaverse in way that you can{" "}
          <span style={{ color: "#0295FA" }}>Trust</span>
        </p>
      </div>

      <Row className="">
        {first.map((item, i) => (
          <Col
            xxl={3}
            xl={3}
            lg={3}
            md={6}
            sm={6}
            xs={6}
            key={i}
            className="mb-sm-5"
          >
            <div
              className="press-card h-100 img-zoom-animation"
              role="presentation"
              onClick={() => pressCardClick(item.link)}
            > 
              <img src={item.image} alt="img" className="press-img" />
              <div className="m-2 d-flex flex-column">
                <Image src={item.logo} alt="img" height={50} width="100%" />
                {window.innerWidth < 600 ? (
                  <small className="mb-0">
                    {item.description.slice(0, 50) + "..."}
                  </small>
                ) : (
                  <small className="mb-0">
                    {item.description.slice(0, 105) + "..."}
                  </small>
                )}
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default PressRelease;
