import React from "react";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";

function Meta() {
  const navigate = useNavigate();
  const UserToken = localStorage.getItem("UserToken");
  return (
    <section className="stadium" id="meta">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="metafoolballtop">
              <h2>Meta-Football</h2>
              <p>
                Play Football with your friends, colleagues and meet new <br />{" "}
                people. Have an existing new game
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ul className="footballlist">
              <Fade bottom>
                <li>
                  <div className="leftfootball">
                    <img
                      src={
                        require("../../assets/images/football/multiplayer.png")
                          .default
                      }
                      alt="img"
                    />
                  </div>
                  <div className="rightfootball">
                    <h3>Multiplayer</h3>
                  </div>
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <div className="leftfootball">
                    <img
                      src={
                        require("../../assets/images/football/interactive.png")
                          .default
                      }
                      alt="img"
                    />
                  </div>
                  <div className="rightfootball">
                    <h3>Interactive</h3>
                  </div>
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <div className="leftfootball">
                    <img
                      src={
                        require("../../assets/images/football/custom-avatar.png")
                          .default
                      }
                      alt="img"
                    />
                  </div>
                  <div className="rightfootball">
                    <h3>
                      Custom <br /> Avatar
                    </h3>
                  </div>
                </li>
              </Fade>
              <Fade bottom>
                <li>
                  <div className="leftfootball">
                    <img
                      src={
                        require("../../assets/images/football/socialise.png")
                          .default
                      }
                      alt="img"
                    />
                  </div>
                  <div className="rightfootball">
                    <h3>
                      Socialize <br /> Interactive
                    </h3>
                  </div>
                </li>
              </Fade>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Meta;
