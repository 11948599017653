import React from "react";
import { Modal } from "react-bootstrap";
import "./styles.css";

function MessageModal({ msgShow, setMsgShow,message,success,heading }) {
  return (
    <Modal
      show={msgShow}
      onHide={()=> {setMsgShow(false)}}
      className="my-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-body schedulebody">
        <div className="logingrayinn mainloginin">
          <button
            type="button"
            className="btn-close"
            onClick={() => {setMsgShow(false)}}
          ></button>
          <h3 className="text-light text-center font-weight-bold">
            {
              success ? "Success !" : heading ? "Success !" : "Warning !"
            }
          </h3>
          <p className="text-light text-center">{message}</p>
          <i></i>
        </div>
      </div>
    </Modal>
  );
}

export default MessageModal;
