//eslint-disable-next-line
import S3 from "react-aws-s3";

export const getMethod = async (item) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + item.url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": item.authtoken,
      },
    });
    const json = await response.json();
    if (json.success == false) {
      if (json.message == "Token Expired") {
        localStorage.clear();
        window.location.replace("/");
      }
    }
    return json;
  } catch (error) {
    // console.log("get api error", error);
    return false;
  }
};

export const postMethod = async (item) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + item.url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": item.authtoken,
      },
      body: JSON.stringify(item.params),
    });
    const json = await response.json();
    if (json.success == false) {
      if (json.message == "Token Expired") {
        localStorage.clear();
        window.location.replace("/");
      }
    }
    return json;
  } catch (error) {
    // console.log("post api error", error);
    return false;
  }
};

export const Slicer = (address) => {
  if (address.length < 9) {
    return address;
  } else {
    let front = address.slice(0, 4);
    let back = address.substr(address.length - 4);
    return front + "...." + back;
  }
};

export const FormatDate = (dateString) => {
  let newFormat = new Date(dateString).toString().substring(4, 15).split(" ");
  return newFormat[1] + " " + newFormat[0] + " " + newFormat[2];
};

export const FormatTime = (dateString) => {
  // console.log("format time value=",dateString)
  // let dummy = dateString.slice(0,19);
  // let result = dummy.replace("T","")
  // console.log("result",result)
  // var today = new Date(result);
  // var hours = today.getHours();
  // var minutes = "0" + today.getMinutes();
  // var AmOrPm = hours >= 12 ? "pm" : "am";
  // hours = hours % 12 || 12;
  // var finalTime = hours + ":" + minutes.substr(-2) + " " + AmOrPm;
  // return finalTime;
  // let value = dateString.split("T");
  // let datefull = value[0] + " " + value[1].slice(0, 8);
  let checkdata = new Date(dateString);
  let datemin = "0" + new Date(checkdata).getMinutes();
  let datehour = String(new Date(checkdata).getHours() + 0).padStart(2, "0");
  var AmOrPm = datehour >= 12 ? "PM" : "AM";
  datehour = String(datehour % 12 || 12).padStart(2, "0");
  return datehour + ":" + datemin.substr(-2) + " " + AmOrPm;
};

const config = {
  bucketName: "metabloqs-nft",
  dirName: "metabloqs-metaverseLandingpage",
  region: "eu-central-1",
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY_ID,
};
export const ReactS3Client = new S3(config);
